import axios from 'axios';

const CustomAxios = () => {

  const defaultOptions = {
    baseURL: process.env.REACT_APP_OASIS_BE_URL,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  // Create instance
  const instance = axios.create(defaultOptions);

  const WHITELISTED_URLS = [
    "oasis/MemberSignup/",
    "oasis/MemberLogin/",
    "oasis/forgot_password/",
    "oasis/reset_password/",
  ]

  // let failedRequest = null; // Store failed request

  // **Show Full-Screen Offline Message with Warning**
  const showOfflineMessage = () => {
    const existingMessage = document.getElementById("offline-message");
    if (!existingMessage) {
      const div = document.createElement("div");
      div.id = "offline-message";
      div.style.position = "fixed";
      div.style.top = "0";
      div.style.left = "0";
      div.style.width = "100vw";
      div.style.height = "100vh";
      div.style.background = "rgba(0, 0, 0, 0.9)";
      div.style.color = "white";
      div.style.display = "flex";
      div.style.flexDirection = "column";
      div.style.alignItems = "center";
      div.style.justifyContent = "center";
      div.style.fontSize = "1.5rem";
      div.style.fontWeight = "bold";
      div.style.textAlign = "center";
      div.style.zIndex = "99999";
      div.style.padding = "20px";

      // ✅ Main Offline Message
      const mainMessage = document.createElement("p");
      mainMessage.innerText = "❌ No internet connection. Please check your network.";
      mainMessage.style.marginBottom = "10px";

      // ✅ Warning Message for Data Loss
      const warningMessage = document.createElement("p");
      warningMessage.innerText = "⚠️ Page will be automatically available once you are online. If you refresh the page without returning back online, you will lose any unsaved work.";
      warningMessage.style.fontSize = "1rem";
      warningMessage.style.fontWeight = "normal";
      warningMessage.style.maxWidth = "80%";
      warningMessage.style.marginBottom = "20px";

      // // ✅ Retry Button
      // const retryButton = document.createElement("button");
      // retryButton.innerText = "🔄 Retry Request";
      // retryButton.style.marginTop = "20px";
      // retryButton.style.padding = "10px 20px";
      // retryButton.style.background = "white";
      // retryButton.style.color = "red";
      // retryButton.style.fontSize = "1rem";
      // retryButton.style.fontWeight = "bold";
      // retryButton.style.border = "none";
      // retryButton.style.borderRadius = "5px";
      // retryButton.style.cursor = "pointer";

      // // ✅ Retry API Call when clicked
      // retryButton.addEventListener("click", retryRequest);

      // Append everything
      div.appendChild(mainMessage);
      div.appendChild(warningMessage);
      // div.appendChild(retryButton);
      document.body.appendChild(div);
    }
  };

  // **Hide Offline Message**
  const hideOfflineMessage = () => {
    const message = document.getElementById("offline-message");
    if (message) {
      message.remove();
    }
  };

  // // **Hide Online Message**
  // const hideOnlineMessage = () => {
  //   const message = document.getElementById("online-notification");
  //   if (message) {
  //     message.remove();
  //   }
  // };

  // // **Show "Back Online" Notification with Close Button**
  // const showOnlineNotification = () => {
  //   const existingNotification = document.getElementById("online-notification");
  //   if (!existingNotification) {
  //     const div = document.createElement("div");
  //     div.id = "online-notification";
  //     div.style.position = "fixed";
  //     div.style.bottom = "20px";
  //     div.style.left = "50%";
  //     div.style.transform = "translateX(-50%)";
  //     div.style.background = "green";
  //     div.style.color = "white";
  //     div.style.padding = "10px 20px";
  //     div.style.borderRadius = "5px";
  //     div.style.zIndex = "1001";
  //     div.style.fontSize = "1rem";
  //     div.style.fontWeight = "bold";
  //     div.style.display = "flex";
  //     div.style.alignItems = "center";
  //     div.style.gap = "10px";
    
  //     // ✅ Add notification text
  //     const text = document.createElement("span");
  //     text.innerText = "✅ You’re back online!";
    
  //     // ✅ Add close (X) button
  //     const closeButton = document.createElement("button");
  //     closeButton.innerText = "✖";
  //     closeButton.style.background = "transparent";
  //     closeButton.style.color = "white";
  //     closeButton.style.border = "none";
  //     closeButton.style.fontSize = "1.2rem";
  //     closeButton.style.cursor = "pointer";
    
  //     // ✅ Remove notification when user clicks the close button
  //     closeButton.addEventListener("click", () => {
  //       div.remove();
  //     });
    
  //     // Append elements
  //     div.appendChild(text);
  //     div.appendChild(closeButton);
  //     document.body.appendChild(div);
  //   }
  // };

  // **Intercept request to check for internet connection**
  instance.interceptors.request.use(
    (config) => {
      if (typeof navigator !== "undefined" && !navigator.onLine) {
        // failedRequest = config;
        // hideOnlineMessage()
        showOfflineMessage();
        return Promise.reject(new Error("Network Error: No Internet"));
      }

      const access_token = localStorage.getItem('authToken');
      const refresh_token = localStorage.getItem('refreshToken');
  
      if (WHITELISTED_URLS.includes(config.url)) {
        return config;
      }
  
      if (access_token && refresh_token) {
        config.headers.Authorization = `Bearer ${access_token}`;
        if (config.url === "oasis/photoupload/") {
          config.headers['Content-Type'] = 'application/octet-stream';
        }
        return config;
      } else {
        localStorage.clear();
        window.location.reload();
        return config;
      }

    },
    (error) => Promise.reject(error)
  );

  // **Intercept response for token refresh & retry logic**
  instance.interceptors.response.use(
    (res) => res,
    async (err) => {
      const originalConfig = err.config;

      if (!WHITELISTED_URLS.includes(originalConfig.url) && err.response?.status === 401) {
        let errorCode;

        if (err.config.responseType === "blob") {
          const blobToJson = async (blob) => {
            try {
              const text = await blob.text();
              return JSON.parse(text);
            } catch (error) {
              console.error("Error parsing blob as JSON:", error);
              return null;
            }
          };
          await blobToJson(err.response.data).then((jsonObj) => (errorCode = jsonObj.code));
        } else if (err.config.responseType === "arraybuffer") {
          const arrayBuffer = err.response.data;
          const textDecoder = new TextDecoder("utf-8");
          try {
            const jsonObj = JSON.parse(textDecoder.decode(arrayBuffer));
            errorCode = jsonObj.code;
          } catch (error) {
            console.error("Error parsing Array Buffer as JSON:", error);
          }
        } else {
          errorCode = err.response.data?.code;
        }

        if (!originalConfig._retry && errorCode === "ATExp") {
          originalConfig._retry = true;

          try {
            const rs = await axios.post(defaultOptions.baseURL + "oasis/refresh_token/", {
              refreshToken: localStorage.getItem("refreshToken"),
            });

            const { access_token } = rs.data;
            localStorage.setItem("authToken", access_token);

            return instance(originalConfig);
          } catch (error) {
            if (error.response?.status === 401 && error.response.data.code === "RTExp") {
              localStorage.clear();
              window.location.reload();
            }
            return Promise.reject(error);
          }
        }
      }
      return Promise.reject(err);
    }
  );

  // // **Retry Failed Request**
  // const retryRequest = async () => {
  //   if (failedRequest) {
  //     try {
  //       // const response = await instance(failedRequest);
  //       // console.log("Retried request successful:", response);
  //       failedRequest = null;
  //       hideOfflineMessage();
  //       return instance(failedRequest);
  //     } catch (error) {
  //       console.error("Retry failed:", error);
  //     }
  //   }
  // };

  // **Call `retryRequest` When Internet is Restored**
  window.addEventListener("online", () => {
    hideOfflineMessage();
    // showOnlineNotification();
    // retryRequest(); // 🔥 Now retrying the request when back online
  });

  return instance; // Returning the instance from function
};

export default CustomAxios();