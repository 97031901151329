import { Box, Typography, Button, IconButton, Tooltip } from "@mui/material"
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import DownloadIcon from '@mui/icons-material/Download';
import { useState, useRef, useEffect } from "react";
import CustomAxios from "../utils/CustomAxios"
import AccountBalanceSharpIcon from '@mui/icons-material/AccountBalanceSharp';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { useTheme } from '@mui/material/styles';
import MuiSearchBar from "../elements/Mui/mui_searchbar"
import MuiButton from "../elements/Mui/mui_button";
import MuiInput from "../elements/Mui/mui_input";

export const CoOpBank =()=>{

    const theme = useTheme();
    
    const [records,setRecords] = useState([])
    const [id, setId] = useState('')
    const [newbank, setNewBank] = useState(false)
    const [coopbank, setCoopBank] = useState(null)
    const [selbank, setSelBank] = useState('')
    const [accno, setAccNo] = useState(null)
    const [update, setUpdate] = useState(false)
    const [success, setSucces] = useState(false)
    const [errors, setErrors] = useState('')
    useState(()=>{
        const getMaster= async ()=>{
            const response = await CustomAxios.get('oasis/form_details/')
            console.log(response.data)
            setRecords(response.data.coopBankname)
        }
        getMaster()
    },[])

    useEffect(()=>{
        if (coopbank==null){
            setSelBank([])

        }
    },[coopbank])

    useEffect(()=>{
        if(success==true){
         setTimeout(() => {
             setSucces(false);
         }, 5000);
        }
 },[success])

    const SelectedBank = async (rowdata) =>{
        let response
        console.log(rowdata)
        if (coopbank!==null){
            
        await CustomAxios.get(`oasis/master_records/?coopbankname=${coopbank}&param=coop_bank`).then((response)=>setSelBank([response.data.data]))}
        else{
            console.log("here")
            response = await CustomAxios.get(`oasis/master_records/?coopbankname=${rowdata.coopbankname}&param=coop_bank`)
            setUpdate(true)
            setNewBank(true)
            console.log(rowdata.coopid)
            setId(rowdata.coopid)
            setCoopBank(rowdata.coopbankname?.trim())
            setAccNo(rowdata.accno?.trim())

        }
    }

    const handleSubmit = async (e) =>{
        e.preventDefault()
        let payload
        let error
        if (coopbank === ""){
            error = "Co-op Bank name is required"
            setErrors(error)
        }
        else{
            error = ""
            setErrors('')
        }
        if (update==true){
        payload = {
            coopbankname:coopbank,
            accno:accno??null,
            id:id,
            update:update,
            param:"coop_bank"
        }}
        else{
            payload = {
                coopbankname:coopbank,
                accno:accno??null,
                id:id,
                update:update,
                param:"coop_bank"
            }}
            handleApi(payload, error)
        }
        const handleApi = async (payload, error) => {
            if (error == ""){
                const response = await CustomAxios.post('oasis/master_records/', payload)
                    if (response.data?.message=="Success!"){
                        setSucces(true)
                        setCoopBank(response.id)
                    }}

        }

        const exportExcel = () => {
            import('xlsx').then((xlsx) => {
                const worksheet = xlsx.utils.json_to_sheet(records);
                const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
                const excelBuffer = xlsx.write(workbook, {
                    bookType: 'xlsx',
                    type: 'array'
                });
    
                saveAsExcelFile(excelBuffer, 'Co-op');
            });
        };
    
        const saveAsExcelFile = (buffer, fileName) => {
            import('file-saver').then((module) => {
                if (module && module.default) {
                    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                    let EXCEL_EXTENSION = '.xlsx';
                    const data = new Blob([buffer], {
                        type: EXCEL_TYPE
                    });
    
                    module.default.saveAs(data, fileName + EXCEL_EXTENSION);
                }
            });
        };

    const clearform = (e) => {
        e.preventDefault()
        setUpdate(false)
        setNewBank(false)
        setCoopBank('')
        setAccNo('')
    }

    const [filters, setFilters] = useState({
        coopbankname:{value:null, matchMode:FilterMatchMode.CONTAINS},
        accno:{value:null, matchMode:FilterMatchMode.CONTAINS},
    })

    const selectBank = (rowData, rowIndex)=>{
        return(<Tooltip title="View & Edit" arrow><IconButton  onClick={()=>SelectedBank(rowData)}><ModeEditIcon/></IconButton></Tooltip>)
    }

    const [bankColumn, setBankColumn] = useState([
        {field:"coopbankname", header:"Co-Op Bank Name", visible:true, style:{width:"100px"}, filter:true},
        {field:"accno", header:"Account Number", visible:true, style:{width:"200px"}, filter:true},
        { field:"coopbankname",header:"Actions", visible:true,body:selectBank ,style:{width:"100px"}}
    ])
    const handleEnter = (e) => {
        e.preventDefault()
        if(e.key == "Enter"){
            SelectedBank()
        }
       }

       const header = (
        <div className="flex align-items-center justify-content-end gap-2" style={{display:"flex", justifyContent:"flex-end"}}>
            <Typography sx={{fontFamily:"inter",fontSize:"16px",fontWeight:"600",lineHeight:"28px",color:"#101010"}}>Download Co-Op Master</Typography>
            <Button sx={{display:"flex", justifyContent:"flex-end" }} type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" ><DownloadIcon sx={{ color: "#64748B", fontSize: "24px" }} /></Button>
        </div>
    );

    return(
        <Box className="fulldiv" style={{ width: "100%", height: "calc(100vh - 64px)", marginTop: "60px" }} >
            {newbank == false ? 
                <Box>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "20px", height: { sm: 'initial', padding: "0px 20px 0px 20px" }, width: "100%" }}>
                        <Box sx={{ top: "72px", left: "304px", padding: "8px 0px 8px 0px", display: "flex", justifyContent: "space-between", borderBottom: "1px solid #CBD5E1", alignItems: "center", height: "84px" }}>
                            <Box sx={{ maxWidth: "300px", height: "48px", padding: "8px 0px 8px 0px", gap: "12px", display: "flex", alignItems: "center" }}>
                                <Box sx={{ width: "32px", height: "32px" }}>
                                    <AccountBalanceSharpIcon sx={{ width: "32px", height: "32px", color: theme.palette.primary.main }} />
                                </Box>
                                <Typography sx={{ fontFamily: "inter", fontSize: "20px", fontWeight: "600", lineHeight: "28px", color: theme.palette.primary.main }}>Co-operative Information</Typography>
                            </Box>

                            <MuiSearchBar
                                handleEnter={handleEnter}
                                search={coopbank}
                                setSearch={setCoopBank}
                                placeholder="Co-op Bank Name"
                            />
                            
                            <Box sx={{ display: "flex", gap: "20px", width:"330px", justifyContent: "flex-end" }}>
                            <MuiButton
                                variant="contained"
                                onClick={(e) => {
                                    clearform(e)
                                    setNewBank(true)
                                }} 
                                value="Add Bank"
                                icon={<AccountBalanceSharpIcon />}
                            />
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ width: "100%" }}>
                        <DataTable value={coopbank != "" && selbank.length > 0 ? selbank : records} scrollable header={header} style={{ backgroundColor: "#F1F5F9" }} rows={7} dataKey="sn" paginator rowcolumnfinalMembersizeMode="expand" removableSort finalMembersizableColumns filters={filters} filterDisplay="row" emptyMessage="No Bulks found.">
                            <Column header="SN" headerStyle={{ width: '50px' }} body={(data, options) => options.rowIndex + 1}></Column>
                            {bankColumn.filter(col => col.visible).map((col, ind) => <Column className="custom-column-style" style={col.style} key={ind} field={col.field} filterElement={col.filterElement} header={col.header} sortable={col.sortable} body={col.body} filter={col.filter} alignFrozen="left" dataType={col.datatype} filterField={col.filterField} filterPlaceholder={col.filterPlaceholder} frozen={col.frozen} />)}
                        </DataTable>
                    </Box>
                </Box> :
            <form onSubmit={(e)=>{handleSubmit(e)}}>
            <Box sx={{position:"relative"}}>
            {success==true&& <Box sx={{maxWidth:"580px",width:"100%",height:"84px",border:"2px solid #288d2c",background:"#DCFCE7",borderRadius:"4px",display:"flex",gap:"16px",justifyContent:"center",alignItems:"center",position:"absolute",top:"34px",left:"32%",zIndex:"2"}}>
                        <IconButton><DoneIcon onClick={()=>setSucces(false)} sx={{width:"24px",height:"24px"}}/></IconButton>
                                <Box>
                                    <Typography sx={{ alignSelf: "stretch", width: "100%", fontFamily: "inter", fontSize: "16px", fontStyle: "inter", fontWeight: "700", lineHeight: "24px",color:"#16A34A"}}>{update?"Co-operative Information updated successfully":"New Co-operative Information added successfully"}</Typography>
                                </Box>
                        <IconButton><ClearIcon onClick={()=>setSucces(false)} sx={{width:"24px",height:"24px"}}/></IconButton>
                </Box>}
                <Box sx={{height:"calc(100vh - 148px)",overflow:"auto"}}>
                <Box sx={{ display:"flex",flexDirection:"column",gap:"20px",height: {sm:'initial',padding: "0px 20px 0px 20px"},width:"100%" }}>
                    <Box sx={{top:"72px",left:"304px",padding:"8px 0px 8px 0px",display:"flex",justifyContent:"space-between",borderBottom:"1px solid #CBD5E1",alignItems:"center",height:"84px"}}>
                        <Box sx={{maxWidth:"300px",height:"48px",padding:"8px 0px 8px 0px",gap:"12px",display:"flex",alignItems:"center"}}>
                            <Box sx={{width:"32px",height:"32px"}}>
                                <AccountBalanceSharpIcon sx={{width:"32px",height:"32px",color:"#288d2c"}}/> 
                            </Box>
                            <Typography sx={{fontFamily:"inter",fontSize:"20px",fontWeight:"600",lineHeight:"28px",color:"#101010"}}>Co-operative Information</Typography>
                        </Box>
                        <Box sx={{width:"406px",display:"flex",gap:"24px"}}>
                            <MuiButton
                                variant="text"
                                onClick={(e) => clearform(e)}
                                value="Clear"
                                icon={<ClearIcon/>}
                                iconsx={{
                                    color: "#64748B"
                                }}
                            />
                            <MuiButton
                                variant="contained"
                                onClick={(event) => handleSubmit(event)}
                                value="Submit"
                            />
                        </Box>
                    </Box>
                </Box>
                <Box sx={{padding:"30px",display:"flex",flexDirection:"column",gap:"24px"}}>
                    <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                        <Box sx={{maxWidth:"1228px",width:"100%",display:"flex",justifyContent:"space-between",marginRight:"30px",alignItems:"center"}}>
                                <Box sx={{maxWidth:"350px",height:"48px",padding:"8px 0px 8px 0px",gap:"12px",display:"flex",alignItems:"center"}}>
                                        <Box sx={{width:"32px",height:"32px"}}>
                                            <AccountBalanceSharpIcon sx={{width:"32px",height:"32px",color:"#288d2c"}}/> 
                                        </Box>
                                        <Typography sx={{fontFamily:"inter",fontSize:"18px",fontWeight:"600",lineHeight:"28px",color:"#101010"}}>{update==true?"Edit Co-operative Information":"Create Co-operative Information"}</Typography>
                                </Box>
                                </Box>
                              
                            <IconButton onClick={(e)=>{
                                clearform(e)
                                setNewBank(false)}} > <ClearIcon /></IconButton>
                            </Box>
                            <Box sx={{display:"flex", gap:"24px", width:"100%",  flexWrap:"wrap"}}>
                            <Box sx={{display:"flex", gap:"8px", flexDirection:"column", maxWidth:"289px",width:"100%"}}>
                                <MuiInput
                                    required
                                    value={coopbank}
                                    label="Enter Co-op Bank Name"
                                    onChange={(e) => setCoopBank(e.target.value)}
                                    style={{ display:"flex",gap:"8px", width: "289px", height:"36px", borderRadius: "4px" }}
                                />
                                {errors !== '' && <Typography sx={{ display: "inline-block", color: "red", fontWeight: "300", fontSize: "14px" }}>{errors}</Typography>}

                                </Box>
                                <Box sx={{display:"flex", gap:"8px", flexDirection:"column", maxWidth:"289px",width:"100%"}}>
                                    <MuiInput
                                        value={accno}
                                        label="Enter Account Number"
                                        onChange={(e) => setAccNo(e.target.value)}
                                        style={{ display:"flex",gap:"8px", width: "289px", height:"36px", borderRadius: "4px" }}
                                    />

                                </Box>
                                </Box>
                                </Box>
                                </Box>
            </Box>
            </form>}
        </Box>
    )
}