import { Box, Grid, Stack, Typography, Button, Tooltip, Input } from "@mui/material"
import { Clear, Logout, Save, Search } from "@mui/icons-material";
import { MenuItem, Select } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import MoneyIcon from '@mui/icons-material/Money';
import { useEffect, useState, useRef } from "react";
import axios from "axios"
import { LinkHeader } from "../components/linkHeader"
import { ProgressBar } from 'primereact/progressbar';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import moment from 'moment-timezone';
import CustomAxios from "../utils/CustomAxios"
import SavingsOutlinedIcon from '@mui/icons-material/Savings';
import ClearIcon from '@mui/icons-material/Clear';
import { useMediaQuery } from "react-responsive";
import MuiAutocomplete from "../elements/Mui/mui_Autocomplete";
import MuiInput from "../elements/Mui/mui_input";
import MuiDatePicker from "../elements/Mui/mui_date_picker";
import dayjs from "dayjs";
import MuiSearchBar from '../elements/Mui/mui_searchbar';
import MuiButton from "../elements/Mui/mui_button";
import MuiInputAmount from "../elements/Mui/mui_input_amount";

export const SpecificDeposit = () => {
    const username = window.localStorage.getItem("username");
    const navigate = useNavigate();
    const paymentTypeOptions = [
      { name: "Monthly", code: 0 },
      { name: "Quarterly", code: 1 },
      { name: "Half-Yearly", code: 2 },
      { name: "Yearly", code: 3 },
    ];
    const periodOptions = Array.from({ length: 7 }).map((_, i) => {
      return { name: `${i + 1}`, code: i + 1 };
    });
    // console.log(periodOptions)
    const [details, setDetails] = useState({
      memberid: "",
      bank: null,
      depositDate: null,
      depositAmt: "",
      bankName: null,
      chequeNo: "",
      narration: "",
      period: null,
      intRate: "",
      intMon: "",
      intYear: "",
      paymentType: null,
      dateOfExpiry: null,
      createdby: "",
    });
    const [memberName, setMemberName] = useState("");
    const [bankOptions, setBankOptions] = useState("");
    const [depositBankOptions, setDepositBankOptions] = useState("");
    const [receiptNo, setReceiptNo] = useState("");
    const [depositNo, setDepositNo] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState("");
    const isMobile = useMediaQuery({ maxWidth: 600 });
    const [showMessage, setShowMessage] = useState(false);
    const [isFutureDateError, setIsFutureDateError] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState(false)
    const depositAmtRef=useRef(null)
    useEffect(() => {
      try {
        setIsLoading(true);
        CustomAxios.get("oasis/specificDeposit/")
          .then((res) => {
            let res_data = res.data;
            // console.log(res_data)
            let bn_arr = [];
            let dpBn_arr = [];
            res_data?.bankName.map((bn, bn_i) =>
              bn_arr.push({ name: bn, code: bn })
            );
            res_data?.depositBank.map((bn, bn_i) =>
              dpBn_arr.push({ name: bn, code: bn })
            );
            setBankOptions(bn_arr);
            setDepositBankOptions(dpBn_arr);
            setIsLoading(false);
          })
          .catch((err) => {
            //console.error(err)
            setIsLoading(false);
          });
      } catch (err) {
        console.err(err);
      }
    }, []);
  
    const handleChange = (e) => {
      console.log("event target", e.target.value);
      
      let dummy_details = { ...details };
      dummy_details[e.target.name] = e.target.value; // For non-date inputs
      dummy_details.membername = "";
    
      if (e.target.name === "period") {
        e.target.value && CalculateExpiryDate(e.target.value);
      } else {
        setDetails(dummy_details);
      }
    };

    const handleDateChange = (e, error) => {
      
      console.log("Deposit Date: ", e)
      if (error.validationError== "disableFuture")
          setIsFutureDateError(true)
      else if(isFutureDateError)
          setIsFutureDateError(false)
      
      e &&  setDetails(prev => ({...prev, depositDate: e}))
      setIsSubmitted(false);
    }
    
    const GetMemberDetails = async (e) => {
      e.preventDefault();
      if (
        (!isNaN(e.target.value) && Number.isInteger(parseInt(e.target.value))&& details.memberid != "") ||
        memberName != ""
      ) {
        let payload = {};
        if (e.target.name === 'memberid' && details.memberid != "") {
          //console.log(details.memberid)
          setMemberName("");
          payload.memberid = parseInt(details.memberid)
          payload.type = "receipt"
        }
        // else if (e.target.name === 'SDMemName' && memberName != "") {
        //   payload.name = memberName
        // }
  
        try {
          //errors.member= "";
          // setIsLoading(true);
          CustomAxios.post(`oasis/getmember/`, payload)
            .then((data) => {
              // console.log(data)
              let res_data = data.data;
              setMemberName(res_data.membername);
              details.memberid = res_data.memberid;
              setIsLoading(false);
            })
            .catch((err) => {
              //console.error(err)
              // setIsLoading(false);
              errors.member = "not available";
            });
        } catch (err) {
          // console.error(err)
          errors.member = "not available";
        }
      }
    };
  
    const CalculateExpiryDate = (period) => {
    //   console.log(period);
      if (period.code) {
        // console.log("we are here");
        let noOfMonths = period.code * 12;
        let dpDate = new Date(details.depositDate);
        let expiry_date = new Date(
          dpDate.setMonth(dpDate.getMonth() + noOfMonths)
        );
        //console.log(expiry_date)
        if (details.intRate && details.depositAmt) {
          console.log("we are in if");
          let int = details.intRate;
          let noOfMonths = parseInt(period.code) * 12;
          let total_int = null;
          let month_int = null;
  
          total_int = (Math.round(parseFloat(details.depositAmt) * parseInt(int) * 100) / 100) / 100 * (Math.round(noOfMonths / 12 * 100) / 100)
          month_int = Math.round((total_int / noOfMonths) * 100) / 100;
          setDetails({
            ...details,
            period: period,
            intRate: int,
            intMon: month_int,
            intYear: parseFloat(month_int * 12).toFixed(2),
            dateOfExpiry: expiry_date,
          });
        } else {
        //   console.log("we are in else");
        //   console.log(expiry_date);
          setDetails({
            ...details,
            period: period,
            dateOfExpiry: expiry_date,
          });
        }
      }
    };
  
    const CalculateInterestRate = (val) => {
      // console.log("---------coming",details.depositAmt , e,details.period.code)
      let int = parseInt(val);
      isNaN(int)
        ? setDetails({ ...details, intRate: val })
        : setDetails({ ...details, intRate: int });
        if (details.depositAmt && int && details.period.code) {
            let noOfMonths = parseInt(details.period.code) * 12;
            let total_int = null;
            let month_int = null;
            
            total_int = (Math.round(parseFloat(details.depositAmt) * parseInt(int) * 100) / 100) / 100 * (Math.round(noOfMonths / 12 * 100) / 100)
            month_int = Math.round((total_int / noOfMonths) * 100) / 100;
            // console.log(month_int,total_int)
            setDetails({
              ...details,
              intRate: int,
              intMon: month_int,
              intYear: parseFloat(month_int * 12).toFixed(2),
            });
      }
    };
  
    const handleSave = (e) => {
      e.preventDefault();
      let dummy_details = { ...details };

      dummy_details.depositDate = dayjs(dummy_details.depositDate)?.format("YYYY-MM-DDTHH:mm:ss[Z]");
      dummy_details.dateOfExpiry = dayjs(dummy_details.dateOfExpiry)?.format("YYYY-MM-DDTHH:mm:ss[Z]");

      dummy_details.createdby = username;
      dummy_details.membername = memberName;
      dummy_details.depositAmt = parseFloat(dummy_details.depositAmt);
      Object.keys(dummy_details).map((key) => {
        if (typeof dummy_details[key] === "object" && dummy_details[key]?.name) {
          // console.log(key,dummy_details[key].code)
          dummy_details[key] = dummy_details[key].code;
        }
      });
      let empty_values = Object.keys(dummy_details).filter(
        (key) => !dummy_details[key]
      );
      if (
        ((empty_values.length === 1 && empty_values[0] === "paymentType") ||
          empty_values.length === 0) &&
        !depositNo
      ) {
        // console.log(dummy_details)
        try {
          setErrors("");
  
          setIsLoading(true);
          CustomAxios.post("oasis/specificDeposit/", dummy_details)
            .then((res) => {
              let res_data = res.data;
              // console.log("---api res----",res_data)
              setDepositNo(res_data.depositId);
              setReceiptNo(res_data.receiptId);
              setIsLoading(false);
  
              setShowMessage(true);
  
              setTimeout(() => {
                setShowMessage(false);
              }, 3000);
            })
            .catch((err) => {
              //console.error(err)
              setIsLoading(false);
            });
        } catch (err) {
          console.error(err);
        }
      } else {
        let dummy_errors = {};
        empty_values.map((key, key_i) => (dummy_errors[key] = "Required"));
        setErrors(dummy_errors);
        //console.info(empty_values)
        //console.info(dummy_details)
      }
    };
  
    const handleSearch = (e) => {
      e.preventDefault();
      if (depositNo) {
        try {
          setErrors("");
          setIsLoading(true);
          CustomAxios.get(`oasis/specificDeposit/?dno=${depositNo}`)
            .then((res) => {
              let res_data = res.data;
              if (res_data.memberid) {
                if (res.paymentType > 3) {
                  res.paymentType = 3;
                }
                depositBankOptions.map(
                  (obj) => obj.name === res_data.bank && (res_data.bank = obj)
                );
                periodOptions.map(
                  (obj) => obj.name == res_data.period && (res_data.period = obj)
                );
                bankOptions.map(
                  (obj) =>
                    obj.name === res_data.bankName && (res_data.bankName = obj)
                );
                paymentTypeOptions.map((obj) => {
                  let code = res_data.paymentType;
                  obj.code === code && (res_data.paymentType = obj);
                });
                res_data.depositDate = new Date(res_data.depositDate);
                res_data.dateOfExpiry = new Date(res_data.dateOfExpiry);
                setMemberName(res_data.memberName);
                // console.log(res_data)
                setDetails({...res_data, depositAmt: res_data.depositAmt, intMon: res_data.intMon, intYear: res_data.intYear});
                setReceiptNo(res_data.receiptNo);
              }
              setIsLoading(false);
            })
            .catch((err) => {
              //console.error(err)
              setIsLoading(false);
            });
        } catch (err) {
          console.log(err);
        }
      } else {
        setErrors({ depositNo: true });
      }
    };
    
    const handleClear = (e) => {
      setDetails({
        memberid: "",
        bank: null,
        depositDate: "",
        depositAmt: "",
        bankName: null,
        chequeNo: "",
        narration: "",
        period: null,
        intRate: "",
        intMon: "",
        intYear: "",
        paymentType: null,
        dateOfExpiry: "",
        createdby: "",
      });
      setMemberName("");
      setDepositNo("");
      setReceiptNo("");
      setErrors("");
    };
    const handleclick = (e) => {
      e.preventDefault();
      if (e.key == "Enter") {
        GetMemberDetails(e);
      }
    };
    const handleDownloadReceipt = (e) => {
      e.preventDefault();
      CustomAxios.get("oasis/download-SDreceipt-all/" + depositNo, {
        responseType: "blob",
      })
        .then((res) => {
          if (res.data) {
            // Create a URL for the PDF blob
            const fileURL = URL.createObjectURL(res.data);
            //console.log(fileURL)
  
            // Open the PDF in a new window/tab
            const win = window.open(fileURL, "_blank");
          }
        })
        .catch((err) => console.error(err));
    };
  
    const handleKeyPress = (e) => {
      if (e.key === "Enter") {
        handleSearch(e);
      }
    };
  
    return (
      <Box
        sx={{
          width: "100%",
          backgroundColor: isMobile && "#f2f6fc",
          height: isMobile ? "100vh" : "calc(100vh - 72px)",
          marginTop: "60px",
        }}
      >
        {/* <LinkHeader/> */}
        {isLoading ? (
          <Stack
            rowGap="10px"
            sx={{
              width: "100%",
              height: "87vh",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography>Please Wait</Typography>
            <ProgressBar
              mode="indeterminate"
              style={{ width: "25%", height: "6px" }}
            />
          </Stack>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: isMobile ? "100vh" : "calc(100vh - 148px)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                height: { sm: "initial", padding: "0px 20px 0px 20px" },
                width: "100%",
              }}
            >
              <Box
                sx={{
                  top: "72px",
                  left: "304px",
                  padding: "8px 0px 8px 0px",
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #CBD5E1",
                  alignItems: "center",
                  height: "84px",
                }}
              >
                <Box
                  sx={{
                    maxWidth: "100%",
                    height: "48px",
                    padding: "8px 0px 8px 0px",
                    gap: "12px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ width: "32px", height: "32px" }}>
                    <SavingsOutlinedIcon
                      sx={{ width: "32px", height: "32px", color: "#288d2c" }}
                    />
                  </Box>
                  <Typography
                    sx={{
                      fontFamily: "inter",
                      fontSize: "20px",
                      fontWeight: "600",
                      lineHeight: "28px",
                      color: "#101010",
                      width: "100%",
                    }}
                  >
                    Specific Deposit
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", gap: "20px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <MuiSearchBar
                      handleEnter={handleKeyPress}
                      search={depositNo}
                      setSearch={setDepositNo}
                      placeholder="Deposit #"
                    />
                  </Box>
                </Box>
  
                <Box sx={{ display: "flex", gap: "20px", width:"330px", justifyContent: "flex-end" }}>
  
                    <MuiButton
                      variant="text"
                      onClick={(e) => {
                        handleClear(e);
                      }}
                      value="Clear"
                      icon={<ClearIcon />}
                      iconsx={{
                        color: "#64748B"
                      }}
                    />
                    <MuiButton
                      variant="contained"
                      onClick={(e) => {
                    handleSave(e);
                  }}
                      value="Submit"
                    />
                </Box>
              </Box>
            </Box>
      <Grid container spacing={5} sx={{ padding: "50px 30px 10px 30px"}}>
      {/* 1st row - 4 input boxes */}
      <Grid item xs={12} sm={6} md={2}>
        <MuiAutocomplete
          isSubmitted={isSubmitted}
          validationErrMsg={!details.bank && "Deposit Bank Name is required"}
          id="bank"
          label="Select Bank Deposit *"
          className={errors.bank ? "p-invalid" : ""}
          options={depositBankOptions}
          getOptionLabel={(option) => option.name}
          value={details.bank}
          onChange={(event, newValue) =>
            handleChange({ target: { name: "bank", value: newValue } })
          }
          disabled={false}
          autoCompleteStyle={{ maxWidth: "95%" }}
        />
        {errors.transDate !== "" && (
          <Typography
            sx={{
              color: "red",
              fontSize: "15px",
              position: "absolute",
              top: "35px",
            }}
          >
            {errors.transDate}
          </Typography>
        )}
        {/* </Box> */}
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <MuiDatePicker
          // required={true}
          isSubmitted={isSubmitted}
          validationErrMsg={details.depositDate?details.depositDate.$d == "Invalid Date"?!details.depositDate ? "Deposit Date is required" : "Invalid Date":isFutureDateError&&"Future dates not allowed. Enter only past or today date.":"Deposit Date is required"}
          className={errors.depositDate ? "p-invalid" : ""}
          name="depositDate"
          label="Deposit Date *"
          value={details.depositDate ? dayjs(details.depositDate) : null}
          onChange={handleDateChange}
          format="DD-MM-YYYY"
          sx={{ maxWidth: "95%" }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <MuiInput
          type="text"
          label="Receipt No"
          InputProps={{ readOnly: true }}
          variant="outlined"
          value={receiptNo}
          sx={{
            fontSize: "14px",
            maxWidth: "95%"
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <Box sx={{display: 'flex', gap: '20px'}}>
        <MuiInput
          type="number"
          label="Deposit No"
          variant="outlined"
          className={errors.depositNo ? "p-invalid" : ""}
          value={depositNo}
          onChange={(e) => setDepositNo(e.target.value)}
          onKeyDown={(e) => handleKeyPress(e)}
          sx={{
            borderRadius: "4px",
            fontSize: "14px",
            maxWidth: {xs: "95%", sm: "95%", md:"30%"},
            backgroundColor: "#FFF",
            // marginLeft:"-190px"
          }}
        />
        <Box>
          {depositNo ? (
            <Button onClick={(e) => handleDownloadReceipt(e)}>
              <DownloadForOfflineIcon />
              Download Receipt
            </Button>
          ) : null}
        </Box>
        </Box>
      </Grid>
  
      {/* 2nd row - 2 input boxes */}
      <Grid item xs={12} sm={6} md={2}>
        <MuiInput
          type="number"
          label="Member No"
          name="memberid"
          className={errors.memberid ? "p-invalid" : ""}
          variant="outlined"
          required={true}
          value={details.memberid}
          onChange={(e) => handleChange(e)}
          onKeyUp={(e) => handleclick(e)}
          onBlur={(e) => GetMemberDetails(e)}
          sx={{
            borderRadius: "4px",
            fontSize: "14px",
            maxWidth: "95%",
            backgroundColor: "#FFF",
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={10}>
        <MuiInput
          InputProps={{ readOnly: true }}
          name="SDMemName"
          type="text"
          label="Member Name"
          variant="outlined"
          required
          value={memberName}
          onChange={(e) => setMemberName(e.target.value)}
        //   onKeyUp={(e) => handleclick(e)}
        //   onBlur={(e) => GetMemberDetails(e)}
          sx={{
            maxWidth: {xs: "95%", sm: "95%", md:"58.8%"},
            backgroundColor: "#FFF",
            fontSize: "14px",
            color: "#101010",
          }}
        />
      </Grid>
  
      {/* 3rd row - 4 input boxes */}
      <Grid item xs={12} sm={6} md={2}>
        <MuiInputAmount
            inputKey="amount1_SD"
            ref={depositAmtRef}
            name="depositAmt"
            required
            amount={details.depositAmt}
            setAmount={setDetails}
            label='Deposit Amount'
            sx={{
              maxWidth: "95%",
              borderRadius: "4px",
              fontSize: "14px",
              backgroundColor: "#FFF",
            }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <MuiAutocomplete
          isSubmitted={isSubmitted}
          validationErrMsg={!details?.period && "Loan Period is required"}
          id="period"
          label="Period (years) *"
          className={errors.period ? "p-invalid" : ""}
          options={periodOptions}
          getOptionLabel={(option) => option.name}
          value={details?.period}
          onChange={(event, newValue) =>
            handleChange({
              target: { name: "period", value: newValue },
            })
          }
          disabled={false}
          autoCompleteStyle={{ maxWidth: "95%" }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <MuiAutocomplete
          isSubmitted={isSubmitted}
          validationErrMsg={!details.bankName && "Bank Name is required"}
          id="bankName"
          label="Select Bank Name *"
          className={errors.bankName ? "p-invalid" : ""}
          options={bankOptions}
          getOptionLabel={(option) => option.name}
          value={details.bankName}
          onChange={(event, newValue) =>
            handleChange({
              target: { name: "bankName", value: newValue },
            })
          }
          disabled={false}
          autoCompleteStyle={{ maxWidth: "95%" }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <MuiInput
          type="text"
          label="Cheque No"
          variant="outlined"
          className={errors.chequeNo ? "p-invalid" : ""}
          required={true}
          name="chequeNo"
          value={details.chequeNo}
          onChange={(e) => handleChange(e)}
          sx={{
            borderRadius: "4px",
            fontSize: "14px",
            maxWidth: {xs: "95%", sm: "95%", md:"30%"},
          }}
        />
      </Grid>
  
      {/* 4th row - 4 input boxes */}
      <Grid item xs={12} sm={6} md={2}>
        <MuiInput
          className={errors.intRate ? "p-invalid" : ""}
          type="number"
          label="Int Rate"
          variant="outlined"
          required={true}
          name="intRate"
          value={details.intRate}
          onChange={(e) => {
            CalculateInterestRate(e.target.value);
          }}
          sx={{
            maxWidth: "95%",
            backgroundColor: "#FFF",
            fontSize: "14px",
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <MuiInput
          label="Int Per Month"
          variant="outlined"
          InputProps={{ readOnly: true }}
          value={details.intMon && parseFloat(details.intMon).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          sx={{
            maxWidth: "95%",
            backgroundColor: "#FFF",
            fontSize: "14px",
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <MuiInput
          type="text"
          label="Int Per Year"
          variant="outlined"
          InputProps={{ readOnly: true }}
          value={details.intYear && parseFloat(details.intYear).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          sx={{
            maxWidth: "95%",
            backgroundColor: "#FFF",
            fontSize: "14px",
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <MuiAutocomplete
          isSubmitted={isSubmitted}
          validationErrMsg={!details.paymentType && "Payment Type is required"}
          id="paymentType"
          label="Select Payment Type"
          className={errors.paymentType ? "p-invalid" : ""}
          options={paymentTypeOptions}
          getOptionLabel={(option) => option.name}
          value={details.paymentType}
          onChange={(event, newValue) =>
            handleChange({
              target: { name: "paymentType", value: newValue },
            })
          }
          disabled={false}
          autoCompleteStyle={{ maxWidth: {xs: "95%", sm: "95%", md:"30%"} }}
        />
      </Grid>
  
      {/* 5th row - 2 input boxes */}
      <Grid item xs={12} sm={6} md={2}>
        <MuiDatePicker
          disabled
          disableFuture={false}
          className={errors.depositDate ? "p-invalid" : ""}
          name="dateOfExpiry"
          label="Date Of Expiry"
          value={details.dateOfExpiry ? dayjs(details.dateOfExpiry) : null}
          // onChange={handleDateChange}
          format="DD-MM-YYYY"
          sx={{
            padding: "0px",
            maxWidth: "95%",
            height: "36px",
            display: "flex",
            alignItems: "center",
            borderRadius: "4px",
            fontSize: "14px",
            backgroundColor: "#FFF",
          }}
          style={{
            borderColor: errors.dateOfExpiry ? "red" : "initial",
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={10}>
        <MuiInput
          type="text"
          label="Narration"
          variant="outlined"
          className={errors.narration ? "p-invalid" : ""}
          required={true}
          name="narration"
          value={details.narration}
          onChange={(e) => handleChange(e)}
          sx={{
            width: {xs: "95%", sm: "95%", md:"58.8%"},
            backgroundColor: "#FFF",
            fontSize: "14px",
          }}
        />
      </Grid>
    </Grid> 
            {showMessage && (
              <div
                style={{ marginTop: "1rem", color: "#288d2c", fontSize: "16px" }}
              >
                Save successful!
              </div>
            )}
          </Box>
        )}
      </Box>
    );
  };