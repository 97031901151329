import React, { forwardRef } from "react";
import { Autocomplete, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Popper from "@mui/material/Popper";

const MuiMemberNameAutocomplete = forwardRef(
  (
    {
      disabled,
      value,
      id,
      options, // ✅ Keep it generic (no default array)
      className,
      getOptionLabel = (option) => option || "", // ✅ Ensure string return
      autoCompleteStyle = {},
      textFieldStyle = {},
      InputLabelProps = {},
      renderOption,
      filterOptions,
      onChange,
      label,
      displayEmpty,
      required,
      isSubmitted = false,
      validationErrMsg = "",
      multiline = false,
      maxRows = 3,
      onInputChange,
      open,
      onKeyUp,
      usePopper = false,
      popperStyle = {},
    },
    ref
  ) => {
    console.log("Current options:", options); // ✅ Debugging log

    const defaultStyles = {
      backgroundColor: "#FFF",
      fontSize: "14px",
      color: "#101010",
      fontWeight: "400",
      "& .MuiInputBase-input": {
        color: "#000",
        padding: "0px !important",
      },
      "& .MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: "black",
      },
      ...textFieldStyle,
    };

    return (
      <FormControl fullWidth>
        <Autocomplete
          ref={ref}
          disablePortal
          id={id}
          disabled={disabled}
          value={value}
          onChange={onChange}
          options={options} // ✅ Keep it generic (no modifications)
          required={required}
          className={className}
          displayEmpty={displayEmpty}
          getOptionLabel={getOptionLabel} // ✅ Works with both objects & strings
          filterOptions={filterOptions}
          onInputChange={onInputChange}
          open={open}
          sx={{ ...autoCompleteStyle }}
          renderOption={(props, option) => (
            <li {...props} key={option.id || option}> {/* ✅ Ensure unique keys */}
              {typeof option === "object" ? option.label : option} {/* ✅ Works for both cases */}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              sx={defaultStyles}
              InputLabelProps={InputLabelProps}
              multiline={multiline}
              maxRows={maxRows}
              inputProps={{
                ...params.inputProps,
              }}
              onKeyUp={onKeyUp}
            />
          )}
          PopperComponent={
            usePopper ? (props) => <Popper {...props} style={popperStyle} /> : null
          }
        />
        {isSubmitted && validationErrMsg && (
          <FormHelperText
            style={{
              minHeight: "1em",
              fontFamily: "inter",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "24px",
            }}
            error={isSubmitted && validationErrMsg}
          >
            {validationErrMsg}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
);

export default MuiMemberNameAutocomplete; 