import { Box, Typography, Button } from "@mui/material"
import { useEffect, useRef } from "react"
import { useSelector } from "react-redux"
import ClearAllIcon from '@mui/icons-material/ClearAll';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import DownloadIcon from '@mui/icons-material/Download';
import TableChartIcon from '@mui/icons-material/TableChart';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import { InputText } from "primereact/inputtext";
import { Form } from "./form";
import { Dialog } from 'primereact/dialog';
import SettingsIcon from '@mui/icons-material/Settings';
import { useState } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';

import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { updateModalOpen, } from "../redux/executiveReducer";
import { useDispatch } from "react-redux";
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { CSVLink } from 'react-csv'
import { Checkbox } from "primereact/checkbox";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ClearIcon from '@mui/icons-material/Clear';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

const WithdrawalReport = () => {
    const withdrawalData = useSelector(state => state.executive.withdrawalData)
    const withdrawalType = useSelector(state => state.executive.withdrawalType)
    const [visible, setVisible] = useState(false);
    const [filterState, setFilterState] = useState(false)
    const [loading, setLoading] = useState(false);
    const [selectedCustomers, setSelectedCustomers] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [totalRecords, setTotalRecords] = useState(null)
    const [globalFilterFields, setGlobalFilterFields] = useState([])
    const modalOpen = useSelector(state => state.executive.modalOpen)
    const [arrangeTable, setArrangeTable] = useState(false)
    const dispatch = useDispatch()
    const [exportCSV, setExportCSV] = useState([])
    const [seperatedColumn, setSeperatedColumn] = useState([])
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [selectedColumn, setSelectedColumn] = useState([])
    const [formattedData, setFormattedData] = useState([])
    const dt = useRef(null);
    const formPayload = useSelector(state => state.executive.formPayload)
    const isSidebarOpen = useSelector((state) => state.executive.open);

    const CustomFilter = (options) => {

        return (
            <InputText style={{ width: "182px", height: "36px" }} placeholder="search" value={options.value} onChange={(e) => options.filterApplyCallback(e.target.value,)} />
        );
    };


    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => { options.filterApplyCallback(e.target.value); }} dateFormat="dd-mm-yyyy" placeholder="dd-mm-yyyy" />;
    };

    const formattedDate = (Data) => {
        const originalDate = new Date(Data);
        const month = (originalDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const day = originalDate.getDate().toString().padStart(2, '0');
        const year = originalDate.getFullYear();

        const formattedDate = `${day}-${month}-${year}`

        return formattedDate;
    }
    const onColumnChange = (e) => {
        let column = [...columnVisible]
        let _selectedCategories = [...seperatedColumn];
        if (e.checked) {
            //console.log(e.value)
            let deselect = column.filter(item => item.header == e.value.header).map(item => { item.visible = true; return item })
            //console.log(column)
            deselect = [...column, ...deselect];
            deselect = [...new Set(deselect)]
            //console.log(deselect)
            setSelectedColumn(deselect)
            _selectedCategories.push(e.value);
        }
        else {
            //console.log(e.value)
            let deselect = column.filter(item => item.header == e.value.header).map(item => { item.visible = false; return item })
            //console.log(column)
            deselect = [...column, ...deselect];
            deselect = [...new Set(deselect)]
            //console.log(deselect)
            setSelectedColumn(deselect)
            _selectedCategories = _selectedCategories.filter(category => category.header !== e.value.header);


        }
        setSeperatedColumn(_selectedCategories)

    };
    const applyFilter = () => {
        let columns = [...columnVisible]


        // columns=columns.map(column=>{
        //     selectedColumn.some(item=>)
        //     // console.log(array)
        //     return column

        // })
        //console.log(columns)
        //console.log(selectedColumn)

        setColumnVisible(selectedColumn)
        setArrangeTable(false)
        let exportHeaders = [...exportCSV]
        //console.log(exportHeaders)
        exportHeaders = selectedColumn.filter((item) => item.visible).map(item => { return { label: item.header, key: item.field } })
        //console.log(exportHeaders)

        setExportCSV(exportHeaders)
    }

    const [columnVisible, setColumnVisible] = useState([])
    useEffect(() => {
        new_table()
        //console.log("---inside NM", formattedData[0])
    }, [withdrawalData])
    const netAmount = (rowData) => {
        return (rowData.sh + rowData.sb)
    }
    const summeryReceiptDate = (rowData) => {
        //console.log(rowData.transDate)
        const newdate = rowData.transDate + ""
        const item = newdate.split("T")[0]
        const originalDate = new Date(item);
        const year = originalDate.getFullYear();
        const month = String(originalDate.getMonth() + 1).padStart(2, '0');
        const day = String(originalDate.getDate()).padStart(2, '0');

        let formattedDate = `${day}-${month}-${year}`;

        return formattedDate

    }

    const decimalshare = (rowData) => {
        if (isNaN(rowData.sh) || rowData.sh == null)
            return "0.00";

        return parseFloat(rowData.sh).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
    const decimalsub = (rowData) => {
        if (isNaN(rowData.sb) || rowData.sb == null)
            return "0.00";

        return parseFloat(rowData.sb).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
    const decimalSd = (rowData) => {
        if (isNaN(rowData.sd) || rowData.sd == null)
            return "0.00";

        return parseFloat(rowData.sd).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
    const decimalBr = (rowData) => {
        if (isNaN(rowData.br) || rowData.br == null)
            return "0.00";

        return parseFloat(rowData.br).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
    const decimalTotal = (rowData) => {
        if (isNaN(rowData.total) || rowData.total == null)
            return "0.00";

        return parseFloat(rowData.total).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
    const decimalPr = (rowData) => {
        if (isNaN(rowData.pr) || rowData.pr == null)
            return "0.00";

        return parseFloat(rowData.pr).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
    const decimalIns = (rowData) => {
        if (isNaN(rowData.ins) || rowData.ins == null)
            return "0.00";

        return parseFloat(rowData.ins).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
    const decimalGst = (rowData) => {
        if (isNaN(rowData.gst) || rowData.gst == null)
            return "0.00";

        return parseFloat(rowData.gst).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
    const decimalNet = (rowData) => {
        if (isNaN(rowData.net) || rowData.net == null)
            return "0.00";

        return parseFloat(rowData.net).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
    const decimalBonus = (rowData) => {
        if (isNaN(rowData.bonus) || rowData.bonus == null)
            return "0.00";

        return parseFloat(rowData.bonus).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }

    useEffect(() => {

        if (formPayload['WD'].Withdrawel_type == "summery") {
            //console.log(formattedData)
            setColumnVisible([
                // {  header: "S/N", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, },
                { field: "estate", header: "Estate", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, filterField: "estate", },
                { field: "sh", header: "Share", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, filterField: "sh", body: decimalshare },
                { field: "sb", header: "Subscription", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, filterField: "sb", body: decimalsub },
                { field: "sd", header: "Specific Deposit", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, filterField: "sd", body: decimalSd },
                { field: "br", header: "Borrowing", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, filterField: "br", body: decimalBr },
                { field: "bonus", header: "Bonus", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, filterField: "bonus", body: decimalBonus },
            ])
            setGlobalFilterFields(["estate", "sh", "sb", "br", "bonus"])
        }
        else if (formPayload['WD'].Withdrawel_type == "detailed") {
            setColumnVisible([
                // {  header: "S/N", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, },
                { field: "transDate", header: "Date", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, filterField: "transDate"},
                { field: "cheqNo", header: "Cheq No", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, filterField: "cheqNo", },
                { field: "memberid", header: "Member ID", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, filterField: "memberid", },
                { field: "membername", header: "MemberName", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, filterField: "membername", },
                { field: "narration", header: "Narration", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, filterField: "narration", },
                { field: "sh", header: "Share", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, filterField: "sh", body: decimalshare },
                { field: "sb", header: "Subscription", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, filterField: "sb", body: decimalsub },
                { field: "sd", header: "Specific Deposit", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, filterField: "sd", body: decimalSd },
                { field: "br", header: "Borrowing", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, filterField: "br", body: decimalBr },
                { field: "total", header: "Total", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, filterField: "total", body: decimalTotal },
                { field: "pr", header: "Processing Fee", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, filterField: "pr", body: decimalPr },
                { field: "ins", header: "Ins", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, filterField: "ins", body: decimalIns },
                { field: "gst", header: "GST", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, filterField: "gst", body: decimalGst },
                { field: "net", header: "Net Amount", sortable: true, filter: true, filterPlaceholder: "Search", visible: true, filterElement: CustomFilter, filterField: "net", body: decimalNet },
            ])
            setGlobalFilterFields(["transDate", "sh", "sb", "br", "bonus", "memberid", "membername", "sd", "total", "pr", "ins", "gst", "net"])

        }

    }, [formPayload])



    const clearFilter = () => {
        setFilters(
            {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                membername: { value: null, matchMode: FilterMatchMode.CONTAINS },
                narration: { value: null, matchMode: FilterMatchMode.CONTAINS },
                estate: { value: null, matchMode: FilterMatchMode.CONTAINS },
                state: { value: null, matchMode: FilterMatchMode.CONTAINS },
                sh: { value: null, matchMode: FilterMatchMode.CONTAINS },
                sb: { value: null, matchMode: FilterMatchMode.CONTAINS },
                sd: { value: null, matchMode: FilterMatchMode.CONTAINS },
                br: { value: null, matchMode: FilterMatchMode.CONTAINS },
                total: { value: null, matchMode: FilterMatchMode.CONTAINS },
                pr: { value: null, matchMode: FilterMatchMode.CONTAINS },
                transDate: { value: null, matchMode: FilterMatchMode.CONTAINS },
                memberid: { value: null, matchMode: FilterMatchMode.CONTAINS },
                net: { value: null, matchMode: FilterMatchMode.CONTAINS },
                ins: { value: null, matchMode: FilterMatchMode.CONTAINS },


            }
        )
    }


    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        membername: { value: null, matchMode: FilterMatchMode.CONTAINS },
        narration: { value: null, matchMode: FilterMatchMode.IN },
        estate: { value: null, matchMode: FilterMatchMode.CONTAINS },
        state: { value: null, matchMode: FilterMatchMode.CONTAINS },
        sh: { value: null, matchMode: FilterMatchMode.CONTAINS },
        sb: { value: null, matchMode: FilterMatchMode.CONTAINS },
        sd: { value: null, matchMode: FilterMatchMode.CONTAINS },
        br: { value: null, matchMode: FilterMatchMode.CONTAINS },
        total: { value: null, matchMode: FilterMatchMode.CONTAINS },
        pr: { value: null, matchMode: FilterMatchMode.CONTAINS },
        transDate: { value: null, matchMode: FilterMatchMode.CONTAINS },
        memberid: { value: null, matchMode: FilterMatchMode.CONTAINS },
        net: { value: null, matchMode: FilterMatchMode.CONTAINS },
        ins: { value: null, matchMode: FilterMatchMode.CONTAINS },
        narration: { value: null, matchMode: FilterMatchMode.CONTAINS },



    });
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        //console.log(_filters)

        _filters['global'].value = value;
        //console.log(_filters)

        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    const onSelectAllChange = (event) => {
        const selectAll = event.checked;

        if (selectAll) {

            setSelectAll(true);
            setSelectedCustomers(formattedData);

        } else {
            setSelectAll(false);
            setSelectedCustomers([]);
        }
    };
    const onSelectionChange = (event) => {
        const value = event.value;
        //console.log(value)
        setSelectedCustomers(value);
        //console.log(selectedCustomers)
        setSelectAll(value.length === totalRecords);
    };



    let count = 1
    let cnt = 0
    let arranged = []
    let PFee = []
    let insurance = []
    let statutorydeclaration = []
    let LN = []
    let SD = []
    let BR = []
    let EF = []
    const new_table = () => {
        const printing_object = [];
        if (!withdrawalData || withdrawalData.length === 0) {
            return;
        }    
    
        if (formPayload['WD'].Withdrawel_type === "summery") {
            // Aggregate data based on estate in a single pass
            const estateAggregate = {};

            withdrawalData.forEach(item => {
                const estateKey = `${item.state}_${item.estate}`;
            
                if (!estateAggregate[estateKey]) {
                    estateAggregate[estateKey] = {
                        estate: item.estate,
                        state: item.state,
                        sh: 0,
                        sb: 0,
                        sd: 0,
                        br: 0,
                        bonus: 0,
                        total: 0,
                        Ins: 0,
                        processingfee: 0,
                        sh_transtotal: 0,
                        sb_transtotal: 0,
                        sd_transtotal: 0,
                        br_transtotal: 0,
                        bonus_transtotal: 0
                    };
                }
            
                const data = estateAggregate[estateKey];
                data[item.transtypeid === 21 ? 'sh' : item.transtypeid === 22 ? 'sb' : item.transtypeid === 24 ? 'sd' : item.transtypeid === 25 ? 'br' : item.transtypeid === 34 && 'bonus'] += parseFloat(item.transamount);
                data[item.transtypeid === 21 ? 'sh_transtotal' : item.transtypeid === 22 ? 'sb_transtotal' : item.transtypeid === 24 ? 'sd_transtotal' : item.transtypeid === 25 ? 'br_transtotal' : item.transtypeid === 34 && 'bonus_transtotal'] += parseFloat(item.transtotalamount || 0);
                data.total += parseFloat(item.transamount);
                data.Ins += parseFloat(item.insurance || 0);
                data.processingfee += parseFloat(item.processingfee || 0);
                data.insurance = item.narration.includes("DWS")? item.transtotalamount:0
            });
        
            // Format the aggregated data
            Object.values(estateAggregate).forEach(obj => {
                obj.rowTotal = parseFloat(obj.sh_transtotal + obj.sb_transtotal + obj.sd_transtotal + obj.br_transtotal + obj.bonus_transtotal).toFixed(2);
                obj.deductionTotal = parseFloat(obj.processingfee + obj.Ins).toFixed(2);
                obj.NetAmount = (parseFloat(obj.rowTotal) - parseFloat(obj.deductionTotal)).toFixed(2);
                printing_object.push(obj);
            });
        
            setFormattedData(printing_object);

        } else if (formPayload['WD'].Withdrawel_type === "detailed") {
            const estateAggregate = {};

            // Process withdrawal data to handle duplicates and aggregate directly
            withdrawalData.forEach(item => {
                const key = `${item.memberid}_${item.transdate}`;
        
                if (!estateAggregate[key]) {
                    estateAggregate[key] = {
                        memberid: item.memberid,
                        membername: item.membername,
                        cheqNo: item.narration.includes("DWS") ? "--" : `${item.bankname.trim()} ${item.chequeno}`,
                        narration: item.narration,
                        sh: 0,
                        sb: 0,
                        sd: 0,
                        br: 0,
                        total: 0,
                        ins: 0,
                        pr: 0,
                        gst: 0,
                        transDate: formattedDate(item.transdate),
                        net: 0,
                        bonus: 0,
                        totaldeduction: 0,
                        remarks: item.remarks,
                        estate: item.estate,
                        state: item.state
                    };
                }
        
                const obj = estateAggregate[key];
                switch (item.transtypeid) {
                    case 21:
                    case 26:
                    case 515:
                        obj.sh += parseFloat(item.transtotalamount || 0);
                        break;
                    case 22:
                    case 27:
                    case 517:
                    case 518:
                    case 519:
                        obj.sb += parseFloat(item.transtotalamount || 0);
                        break;
                    case 24:
                    case 31:
                        obj.sd += parseFloat(item.transtotalamount || 0);
                        break;
                    case 25:
                    case 32:
                        obj.br += parseFloat(item.transtotalamount || 0);
                        break;
                    case 34:
                        obj.bonus += parseFloat(item.transtotalamount || 0);
                        break;
                }
        
                obj.pr += item.processingfee ? parseFloat(item.processingfee) : 0;
                obj.ins += item.insurance ? parseFloat(item.insurance) : 0;
                obj.ins = item.narration.includes("DWS")? item.transtotalamount:obj.ins
                obj.net = item.narration.includes("DWS")? item.transtotalamount:obj.net
            });
        
            // Map the aggregated data to an array suitable for setting state
            const printing_object = Object.values(estateAggregate).map(obj => {
                obj.total = obj.sh + obj.sb + obj.sd + obj.br + obj.bonus;
                obj.totaldeduction = obj.pr + obj.ins;
                obj.net = obj.total - obj.totaldeduction;
                return obj;
            });
        
            setFormattedData(printing_object);
    }
}

    const addCurrencyAndCommafy = (num) => {

        let str = parseFloat(num).toFixed(2).toString().split('.');
        if (str[0].length >= 4) {
            str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
        }
        return "RM " + str.join('.');

    }

    const calculateFooter = (data, from) => {
        // console.log(data)
        if (from == "sh") {
            let total = 0;
            formattedData.forEach(item => {
                //    console.log (item)
                if (item.state == data.state) {
                    // console.log("????????????????inside share",item.masterid,masterid,item.typeid)

                    total += parseFloat(item.sh)


                }

            })
            return total
        }
        if (from == "sb") {
            let total = 0;
            formattedData.forEach(item => {
                //    console.log (item)
                if (item.state == data.state) {
                    // console.log("????????????????inside share",item.masterid,masterid,item.typeid)

                    total += parseFloat(item.sb)


                }

            })
            return total
        }
        if (from == "sd") {
            let total = 0;
            formattedData.forEach(item => {
                //    console.log (item)
                if (item.state == data.state) {
                    // console.log("????????????????inside share",item.masterid,masterid,item.typeid)

                    total += parseFloat(item.sd)


                }

            })
            return total
        }
        if (from == "br") {
            let total = 0;
            formattedData.forEach(item => {
                //    console.log (item)
                if (item.state == data.state) {
                    // console.log("????????????????inside share",item.masterid,masterid,item.typeid)

                    total += parseFloat(item.br)


                }

            })
            return total
        }
        if (from == "bonus") {
            let total = 0;
            formattedData.forEach(item => {
                //    console.log (item)
                if (item.state == data.state) {
                    // console.log("????????????????inside share",item.masterid,masterid,item.typeid)

                    total += parseFloat(item.bonus)


                }

            })
            return total
        }
    }
    const footerTemplate = (data) => {
        if (formPayload['WD'].Withdrawel_type == "detailed") {
            let new_pos = columnVisible.filter(item => item.visible).map(item => (item.field))
            //console.log(new_pos)
            const position = new_pos.indexOf("sh");

        }
        else {
            let new_pos = columnVisible.filter(item => item.visible).map(item => (item.field))
            //console.log(new_pos)
            const position = new_pos.indexOf("sh");
            return <>
                {new_pos.includes("sh") && <td colSpan={`${position + 3}`} >
                    <div className="flex justify-content-end font-bold w-full" style={{ display: "flex", justifyContent: "end", width: "100%", fontWeight: "600" }} >Total Share : {addCurrencyAndCommafy(calculateFooter(data, "sh"))}</div>
                </td>}
                {new_pos.includes("sb") && <td >
                    <div className="flex justify-content-end font-bold w-full" style={{ display: "flex", justifyContent: "end", width: "100%", fontWeight: "600" }} >Total Subs : {addCurrencyAndCommafy(calculateFooter(data, "sb"))}</div>
                </td>}
                {new_pos.includes("sd") && <td >
                    <div className="flex justify-content-end font-bold w-full" style={{ display: "flex", justifyContent: "end", width: "100%", fontWeight: "600" }} >Total Special Deposit : {addCurrencyAndCommafy(calculateFooter(data, "sd"))}</div>
                </td>}
                {new_pos.includes("br") && <td >
                    <div className="flex justify-content-end font-bold w-full" style={{ display: "flex", justifyContent: "end", width: "100%", fontWeight: "600" }} >Total Borrowoing : {addCurrencyAndCommafy(calculateFooter(data, "br"))}</div>
                </td>}
                {new_pos.includes("bonus") && <td >
                    <div className="flex justify-content-end font-bold w-full" style={{ display: "flex", justifyContent: "end", width: "100%", fontWeight: "600" }} >Total bonus : {addCurrencyAndCommafy(calculateFooter(data, "bonus"))}</div>
                </td>}</>
        }
    }

    const headerTemplate = (data) => {
        if (formPayload['WD'].Withdrawel_type == "summery") {
            return (
                <div className="flex align-items-center gap-2">

                    <span className="font-bold">State : {data.state}</span>
                </div>
            );


        }
        else if (formPayload['WD'].Withdrawel_type == "detailed") {
            return (
                <div className="flex align-items-center gap-2">

                    <span className="font-bold">Member Name : {data.member?.membername}</span>
                </div>
            );
        }
    }

    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };
    const shTotal = () => {

        let total = 0;

        for (let row of formattedData) {
            if (isNaN(row.sh) || row.sh == null)
                total += 0;
            else
                total += parseFloat(row.sh);
        }

        return total;
    };

    const sbTotal = () => {
        let total = 0;

        for (let row of formattedData) {
            if (isNaN(row.sb) || row.sb == null)
                total += 0;
            else
                total += parseFloat(row.sb);
        }

        return total;
    };
    const sdTotal = () => {
        let total = 0;

        for (let row of formattedData) {
            if (isNaN(row.sd) || row.sd == null)
                total += 0;
            else
                total += parseFloat(row.sd);
        }

        return total;

    }
    const borrowTotal = () => {
        let total = 0;

        for (let row of formattedData) {
            if (isNaN(row.br) || row.br == null)
                total += 0;
            else
                total += parseFloat(row.br);
        }

        return total;

    }
    const Total = () => {
        let total = 0;

        for (let row of formattedData) {
            if (isNaN(row.total) || row.total == null)
                total += 0;
            else
                total += parseFloat(row.total);
        }

        return total;

    }
    const processingFee = () => {
        let total = 0;

        for (let row of formattedData) {
            if (isNaN(row.pr) || row.pr == null)
                total += 0;
            else
                total += parseFloat(row.pr);
        }

        return total;

    }
    const Ins = () => {
        let total = 0;

        for (let row of formattedData) {
            if (isNaN(row.ins) || row.ins == null)
                total += 0;
            else
                total += parseFloat(row.ins);
        }

        return total;

    }
    const gst = () => {
        let total = 0;

        for (let row of formattedData) {
            if (isNaN(row.gst) || row.gst == null)
                total += 0;
            else
                total += parseFloat(row.gst);
        }

        return total;

    }
    const netTotal = () => {
        let total = 0;

        for (let row of formattedData) {
            total += (parseFloat(row.total) - parseFloat(row.pr) - parseFloat(row.ins));
        }

        return total;

    }
    const bonusTotal = () => {
        let total = 0;

        for (let row of formattedData) {
            if (isNaN(row.bonus) || row.bonus == null)
                total += 0;
            else
                total += parseFloat(row.bonus);
        }

        return total;
    }

    const shaTotal = () => {
        return (
            <span>
                <span style={{ fontWeight: 'bold' }}>Share Total : </span>{addCurrencyAndCommafy(shTotal())}
            </span>
        )
    }

    const subTotal = () => {
        return (
            <span>
                <span style={{ fontWeight: 'bold' }}>Subs Total : </span>{addCurrencyAndCommafy(sbTotal())}
            </span>
        )
    }
    const SDTotal = () => {
        return (
            <span>
                <span style={{ fontWeight: 'bold' }}>SD Total : </span>{addCurrencyAndCommafy(sdTotal())}
            </span>
        )
    }

    const BorrowTotal = () => {
        return (
            <span>
                <span style={{ fontWeight: 'bold' }}>Borrow Total : </span>{addCurrencyAndCommafy(borrowTotal())}
            </span>
        )
    }

    const grandTotal = () => {
        if (formPayload['WD'].Withdrawel_type == "detailed") {
            return "Grand Total "

        }
        else if (formPayload['WD'].Withdrawel_type == "summery")

            return (
                <span>
                    <span style={{ fontWeight: 'bold' }}>Grand Total : </span>{" "}
                    {addCurrencyAndCommafy(parseFloat(shTotal()) + parseFloat(sbTotal()) + parseFloat(bonusTotal()) + parseFloat(sdTotal()) + parseFloat(borrowTotal()))}
                </span>
            );
    }

    const MTotal = () => {
        return (
            <span>
                <span style={{ fontWeight: 'bold' }}>Total : </span>{addCurrencyAndCommafy(Total())}
            </span>
        )
    }

    const PTotal = () => {
        return (
            <span>
                <span style={{ fontWeight: 'bold' }}>Processing Total : </span>{addCurrencyAndCommafy(processingFee())}
            </span>
        )
    }

    const ITotal = () => {
        return (
            <span>
                <span style={{ fontWeight: 'bold' }}>Ins Total : </span>{addCurrencyAndCommafy(Ins())}
            </span>
        )
    }

    const GTotal = () => {
        return (
            <span>
                <span style={{ fontWeight: 'bold' }}>GST Total : </span>{addCurrencyAndCommafy(gst())}
            </span>
        )
    }

    const NTotal = () => {
        return (
            <span>
                <span style={{ fontWeight: 'bold' }}>Net Total : </span>{addCurrencyAndCommafy(netTotal())}
            </span>
        )
    }

    const BTotal = () => {
        return (
            <span>
                <span style={{ fontWeight: 'bold' }}>Bonus Total : </span>{addCurrencyAndCommafy(bonusTotal())}
            </span>
        )
    }


    const footerGroupDetailed = (


        <ColumnGroup>
            <Row>
                <Column footer={grandTotal} colSpan={7} footerStyle={{ textAlign: 'right' }} />
                <Column footer={shaTotal} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />
                <Column footer={subTotal} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />
                <Column footer={SDTotal} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />
                <Column footer={BorrowTotal} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />
                <Column footer={MTotal} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />
                <Column footer={PTotal} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />
                <Column footer={ITotal} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />
                <Column footer={GTotal} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />
                <Column footer={NTotal} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />
            </Row>
        </ColumnGroup>
    );

    const footGroupSummary = (
        <ColumnGroup>
            <Row>
                <Column footer={grandTotal} colSpan={3} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />
                <Column footer={shaTotal} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />
                <Column footer={subTotal} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />
                <Column footer={SDTotal} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />
                <Column footer={BorrowTotal} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />
                <Column footer={BTotal} footerStyle={{ textAlign: 'right', fontWeight: 'normal' }} />

            </Row>
        </ColumnGroup>

    )
    
      const exportExcel = (data) => {

          data = data.map((item, index) => {
              return {  SN: index + 1, ...item };
            });
            let filteredData
            let headers
            let requiredColumns
            if(formPayload['WD'].Withdrawel_type=="summery"){
                   requiredColumns = ['SN','state', 'estate', 'sh_transtotal','sb_transtotal','sd_transtotal','br_transtotal','bonus_transtotal','rowTotal','processingfee', 'Ins', 'statutorydeclaration', 'deductionTotal', 'NetAmount' ];

                   const columnHeadersMap = {
                    SN: 'SN',
                    state: 'State',
                    estate: 'Estate Name',
                    sh_transtotal: 'Share',
                    sb_transtotal: 'Subs',
                    sd_transtotal: 'SD',
                    br_transtotal: 'BR',
                    bonus_transtotal: 'BONUS',
                    rowTotal: 'Total',
                    processingfee: 'P.Fee',
                    Ins: 'Ins',
                    statutorydeclaration: 'Stat.Decl',
                    deductionTotal: 'Total Deduction',
                    NetAmount: 'Net Amount'
                  };

        // Filter the data array to include only the required columns
         filteredData = data.map(obj => {
          return Object.keys(obj)
            .filter(key => requiredColumns.includes(key))
            .reduce((acc, key) => {
              acc[key] = obj[key];
              return acc;
            }, {});
         }); 
         headers = requiredColumns.map(header => {
            return { 
                v: columnHeadersMap[header], 
                t: 's', 
                s: { 
                    font: { bold: true,size: 10 } } };
          });
            }
            else{
                 requiredColumns = ['SN','state', 'memberid','membername','transDate','cheqNo','remarks'
                ,'narration','sh','sb','bonus','sd','br','total','pr','ins','gst','totaldeduction','net','estate','region'];

                const columnHeadersMap = {
                    SN: 'SN',
                    state: 'State',
                    memberid: 'Mno',
                    membername: 'Name',
                    transDate: 'W_Dt',
                    cheqNo: 'ChqNo',
                    remarks: 'BulkNo',
                    narration: 'Narration',
                    sh: 'Share',
                    sb: 'Subscription',
                    bonus: 'Bonus',
                    sd: 'SD',
                    br: 'Borrowing',
                    total: 'Total',
                    pr: 'P/Fee',
                    ins: 'Insurance',
                    gst: 'GST',
                    totaldeduction: 'Total Deduction',
                    net: 'Cheque Amount',
                    estate: 'Estate',
                    region: 'Region',
                  };
                   filteredData = data.map(obj => {
                    return requiredColumns.reduce((acc, key) => {
                      acc[key] = obj[key];
                      return acc;
                    }, {});
                  }); 
        headers = requiredColumns.map(header => {
                return { 
                    v: columnHeadersMap[header], 
                    t: 's', 
                    s: { 
                        font: { bold: true,size: 10 } } };
              });
            }

      const additionalRow1 = ['','KOPERASI SERBAGUNA KEBANGSAAN BERHAD (4391)']
      const additionalRow2 = []
      const additionalRow3 = ['',formPayload['WD'].Withdrawel_type=="detailed"?'Withdrawal Report (Detailed)':'Withdrawal Report (Summary)']
      const additionalRow4 = ['',`Date Range : ${formattingdate(formPayload['WD'].dr[0])+" TO "+formattingdate(formPayload['WD'].dr[1])}`]
      const additionalRow5 = ['',`Estate : ${formPayload['WD'].estate}`]
      const additionalRow6 = ['',`Region : ${formPayload['WD'].region}`]
      const additionalRow7 = ['',`State  : ${formPayload['WD'].state==""?"ALL":formPayload['RI'].state}`]
      const additionalRow8 = ['',`Postal Code : ${formPayload['WD'].postal_code}`]
      const additionalRow9 = []
      
          // Convert array of objects to array of arrays (2D array)
          const dataArray = [additionalRow1,additionalRow2,additionalRow3,additionalRow4,additionalRow5,additionalRow6,additionalRow7,additionalRow8,additionalRow9, headers, ...filteredData.map(obj =>
            requiredColumns.map(key => {
                let value;
                const amountRelatedKeys = ['sh_transtotal','sb_transtotal','sd_transtotal','br_transtotal','bonus_transtotal','rowTotal','processingfee','Ins','statutorydeclaration','deductionTotal','NetAmount','sh','sb','bonus','sd','br','total','pr','ins','gst','totaldeduction','net']
                if(amountRelatedKeys.includes(key)){
                    value = parseInt(parseFloat(obj[key]).toFixed(2).split('.')[1]) === 0?parseInt(obj[key]):obj[key]
                }
                else
                    value = obj[key]
              return {
                v: value,
                t: typeof value === 'number' ? 'n' : 's',
                s: {
                  alignment: { horizontal: typeof value === 'number' ? 'right' : 'left' },
                  font: { size: 8 , name: 'Times New Roman', bold: key == requiredColumns[0] } // Set font size to 8
                }
              };
            })
          )];
      
          // Convert data to worksheet
          const ws = XLSX.utils.aoa_to_sheet(dataArray);
      
          // Create workbook and add the worksheet
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      
          // Generate XLSX file and trigger download
          XLSX.writeFile(wb, formPayload['WD'].Withdrawel_type=="detailed"?'Withdrawal Report (Detailed).xlsx':'Withdrawal Report (Summary).xlsx');
      };
    
    const formattingdate = (date) => {
        const originalDate = new Date(date);
        const year = String(originalDate.getUTCFullYear()).slice(2,4);
        const month = String(originalDate.getUTCMonth() + 1).padStart(2, '0');
        const day = String(originalDate.getUTCDate()).padStart(2, '0');

        let formattedDate = `${day}-${month}-${year}`;
        return formattedDate;

    }
    return (
        <Box className="fulldiv" sx={{ width: isSidebarOpen?"calc(100vw - 319px)":"calc(100vw - 95px)", height: "calc(100vh - 64px)", }} >
            <Dialog visible={modalOpen} style={{ width: '790px', boxShadow: "0px 0px 34px 0px rgba(100, 116, 139, 0.50)", background: "#f2f6fc", borderRadius: "0px", }} className="custombar1" draggable={false} position="center" modal={false} closable={false} resizable={false} >
                <Form formAlone={true} api="WD" />
            </Dialog>
            <Box sx={{ marginLeft: "24px", padding: "8px 0px 8px 0px", marginRight: "16px", height: "93vh", }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Box sx={{ display: "flex", gap: "12px", }}>
                        <SummarizeOutlinedIcon sx={{ fontSize: "32px", color: "#288d2c" }} />
                        <Box>
                            <Typography sx={{ fontSize: "20px", fontWeight: "600", lineHeight: "28px", color: "#101010" }}>Withdrawal {formPayload['WD'].Withdrawel_type == "detailed" ? "Detailed" : formPayload['WD'].Withdrawel_type == "summery" && "Summary"} Report</Typography>
                            <Typography sx={{ fontSize: "16px", fontWeight: "400", lineHeight: "24px", color: "#64748B" }}>Found <span style={{ fontWeight: "700" }}>{formattedData.length}</span> records on <span style={{ fontWeight: "700" }}>{formattingdate(formPayload['WD']?.dr[0])}</span> to <span style={{ fontWeight: "700" }}>{formattingdate(formPayload['WD']?.dr[1])}</span> with applied Configure</Typography>
                        </Box>

                    </Box>
                    <Box sx={{ alignSelf: "end" }}>
                        <Button variant="contained" sx={{ background: "#288d2c", "&:hover": { background: "#288d2c" }, gap: "8px", fontSize: "16px", fontWeight: "400", lineHeight: "24px", padding: "12px", textTransform: "none", width: "201px", height: "48px" }} startIcon={<SettingsIcon sx={{ fontSize: "24px", }} />} onClick={() => dispatch(updateModalOpen(true))}>Data Re-Configure</Button>
                    </Box>

                </Box>
                <Box sx={{ marginTop: "24px", }}>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between",width:"100%",flexWrap:"wrap" }}>


                        <Box sx={{ display: "flex", alignItems: "center" }}>

                            <Box>

                                <span className="p-input-icon-left">
                                    <i className="pi pi-search" />
                                    <InputText style={{ width: "655px", height: "36px" }} placeholder="Search" value={globalFilterValue} onChange={onGlobalFilterChange} />
                                </span>
                            </Box>
                            <Box sx={{ color: "#64748B", width: "178px", padding: "12px", display: "flex", alignItems: "center", justifyContent: "center", marginLeft: "16px", alignSelf: "end", cursor: "pointer", textTransform: "none", gap: "8px" }} onClick={clearFilter}>
                                <ClearAllIcon sx={{ fontSize: "24px", color: "#CBD5E1" }} />
                                <Typography sx={{ fontSize: "16px", fontWeight: "400", lineHeight: "24px", color: "#CBD5E1" }}></Typography>
                                Clear Filter
                            </Box>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "16px", alignSelf: 'end' }}>
                            <Box sx={{ padding: "12px" }}> {filterState ? <FilterAltOffIcon sx={{ color: "#64748B", fontSize: "24px", cursor: "pointer" }} onClick={() => setFilterState(false)} /> : <FilterAltIcon sx={{ color: "#64748B", fontSize: "24px", cursor: "pointer" }} onClick={() => setFilterState(true)} />}</Box>
                            <Box sx={{ padding: "12px" }}><DownloadIcon sx={{ color: "#64748B", fontSize: "24px", cursor: "pointer" }} onClick={()=>exportExcel(formattedData)} /> </Box>

                            {/* <CSVLink data={selectedCustomers.length > 0 ? selectedCustomers : formattedData} headers={exportCSV} filename={'table-export.csv'}> <Box sx={{ padding: "12px" }}><DownloadIcon sx={{ color: "#64748B", fontSize: "24px" }} /> </Box></CSVLink> */}
                            <Box sx={{ padding: "12px" }}><TableChartIcon sx={{ color: "#64748B", fontSize: "24px", cursor: "pointer" }} onClick={() => { setArrangeTable(true); setSeperatedColumn(columnVisible) }} /> </Box>

                        </Box>

                    </Box>

                </Box>
                <Box sx={{ marginTop: "24px", }}>
                    <div className="card">
                        <DataTable ref={dt} value={formattedData} filters={filters} paginator rows={10} footerColumnGroup={formPayload['WD'].Withdrawel_type == "detailed" ? footerGroupDetailed : footGroupSummary} scrollable scrollHeight="650px" dataKey="transdetailid" style={{ backgroundColor: "#F1F5F9" }} filterDisplay={filterState ? "row" : ""} loading={loading} columnResizeMode="expand" removableSort resizableColumns groupRowsBy={formPayload['WD'].Withdrawel_type == "summery" ? "state" : null} rowGroupMode={formPayload['WD'].Withdrawel_type == "summery" ? "subheader" : null} rowGroupHeaderTemplate={headerTemplate}
                            selection={selectedCustomers} selectionMode="checkbox" onSelectionChange={onSelectionChange} selectAll={selectAll} filterIcon={false} onSelectAllChange={onSelectAllChange} emptyMessage="No customers found." rowGroupFooterTemplate={footerTemplate} globalFilterFields={globalFilterFields}>
                            <Column styleClass="custom-column-style" selectionMode="multiple" headerStyle={{ width: "100px", background: "#F1F5F9" }} />
                            <Column header="S/No" headerStyle={{ width: '3rem' }} body={(data, options) => options.rowIndex + 1}></Column>
                            {columnVisible.filter(col => col.visible).map(col => <Column className="custom-column-style" style={{ width: "500px" }} field={col.field} filterElement={col.filterElement} header={col.header} sortable={col.sortable} body={col.body} filter={col.filter} dataType={col.datatype} filterField={col.filterField} filterPlaceholder={col.filterPlaceholder} frozen={col.frozen} />)}
                        </DataTable>
                    </div>
                </Box>


            </Box>
            <Dialog visible={arrangeTable} style={{ width: '521px', boxShadow: "0px 0px 34px 0px rgba(100, 116, 139, 0.50)", background: "#f2f6fc", borderRadius: "0px", }} className="custombar1" draggable={false} position="center" modal={true} closable={false} resizable={false} >


                <Box style={{ width: '521px', boxShadow: "0px 0px 34px 0px rgba(100, 116, 139, 0.50)", background: "#f2f6fc", padding: "30px 30px 0px 30px", borderRadius: "0px", display: "flex", flexDirection: "column", gap: "24px", }}>
                    <Box sx={{ display: "flex", gap: "16px", alignItems: "center", background: "#f2f6fc" }}>
                        <TableChartIcon sx={{ fontSize: "24px", color: "#288d2c" }} />
                        <Typography sx={{ fontSize: "18px", fontWeight: 600, lineHeight: "28px" }}>Table Column Configure</Typography>
                    </Box>
                    <Box>


                        <span className="p-input-icon-left">
                            <i className="pi pi-search" />
                            <InputText style={{ width: "461px", height: "36px" }} placeholder="Search" onChange={onGlobalFilterChange} />
                        </span>

                    </Box>
                    <Box sx={{ height: "461px", overflowY: "scroll", display: "flex", flexDirection: "column", gap: "20px" }}>

                        {columnVisible.map((column, ind) => {
                            return (

                                <div key={column.header} style={{ gap: "8px", display: "flex", alignItems: "center" }}>
                                    <Checkbox inputId={column.header} value={column} onChange={onColumnChange} disabled={column.header == "Member #" || column.header == "MemberName"} checked={column.visible} />
                                    <label htmlFor={column.header} className="ml-2">
                                        {column.header}
                                    </label>
                                </div>

                            );
                        })}

                    </Box>
                    <Box sx={{ display: "flex", gap: "16px", alignItems: "center", background: "#F1F5F9", paddingTop: "24px", paddingBottom: "24px", justifyContent: "space-between", marginLeft: "-30px", marginRight: "-30px", }}>

                        <Button variant="contained" sx={{ background: "#288d2c", "&:hover": { background: "#288d2c" }, fontSize: "16px", fontWeight: "400", lineHeight: "24px", padding: "12px", textTransform: "none", width: "138px", height: "48px", marginLeft: "30px" }} startIcon={<CheckCircleOutlineIcon />} onClick={applyFilter} >Apply</Button>
                        <Button variant="outlined" sx={{ borderColor: "#64748B", color: "#64748B", "&:hover": { borderColor: "#64748B" }, fontSize: "16px", fontWeight: "400", lineHeight: "24px", padding: "12px", marginRight: "30px" }} startIcon={<ClearIcon sx={{ color: "#64748B", fontSize: "24px" }} />} onClick={() => setArrangeTable(false)}>Clear</Button>


                    </Box>
                </Box>


            </Dialog >

        </Box>
    )
}


export default WithdrawalReport