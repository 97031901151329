import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';

const MuiSelect = ({ 
  value, 
  onChange, 
  options, 
  label, 
  disabled, 
  iconComponent, 
  helperText, 
  sx, // For custom styles
  style, // For additional inline styles
  required
}) => {
  const defaultStyles = {
    width: "100%",
    backgroundColor: "#FFF",
    fontSize: "14px",
    color: "#101010",
    fontWeight: "800",
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "black", // For WebKit browsers
    },
    ...sx, // Merge any custom styles passed as prop
  };

  return (
    <FormControl
      style={{ width: '100%', height: '36px', display: 'flex', alignItems: 'center' }} 
      disabled={disabled}
      sx={sx}
      required={required}
    >
      {/* The label needs to be inside FormControl, and InputLabel should shrink when a value is selected */}
      <InputLabel shrink sx={{
          color: 'green',
          '&.MuiInputLabel-root.Mui-focused': {
            color: 'green',
          },
          '&.MuiInputLabel-root.Mui-error': {
            color: 'green',
          }
        }}>{label}</InputLabel>
      <Select
        value={value}
        onChange={onChange}
        displayEmpty
        IconComponent={iconComponent}
        style={defaultStyles} // Apply defaultStyles here
        label={label} // Use label here for proper shrink behavior
        required={required}
      >
        <MenuItem value="" disabled>
          Select
        </MenuItem>
        {options.map((option, index) => (
          <MenuItem 
            key={index} 
            value={option.value} 
            sx={{
              fontFamily: 'inter', 
              fontSize: '16px', 
              fontWeight: '400', 
              lineHeight: '24px', 
              color: '#101010'
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {/* {helperText && <FormHelperText>{helperText}</FormHelperText>} */}
    </FormControl>
  );
};

export default MuiSelect;