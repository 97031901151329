import { Box, Stack, Button, Typography, IconButton, Grid } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import ArticleIcon from '@mui/icons-material/Article';
import { Dialog } from "primereact/dialog";
import { useState, useEffect } from 'react'
import { Dropdown } from 'primereact/dropdown';
import { RadioButton } from 'primereact/radiobutton';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import CustomAxios from "../utils/CustomAxios";
import { FilterMatchMode } from 'primereact/api';
import { ProgressBar } from "primereact/progressbar";
import { DataTable } from "primereact/datatable"
import TableChartIcon from '@mui/icons-material/TableChart';
import { Checkbox } from "primereact/checkbox";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ClearIcon from '@mui/icons-material/Clear';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import DownloadIcon from '@mui/icons-material/Download';
import { Column } from "primereact/column"
import { DatePicker } from "@mui/x-date-pickers-pro";
import dayjs from "dayjs";
import DoneIcon from "@mui/icons-material/Done";

import MuiButton from "../elements/Mui/mui_button";
import * as XLSX from 'xlsx';
import MuiAutocomplete from "../elements/Mui/mui_Autocomplete";
import CircularProgress from '@mui/material/CircularProgress';

export function ShareCertificate() {

  const [dialogOpen, setDialogOpen] = useState(false);
  const [certType, setCertType] = useState("New")
  const [transDate, setTransDate] = useState(dayjs())
  const [certStatusDate, setCertStatusDate] = useState(dayjs())
  const [memIdRange, setMemIdRange] = useState([0, 0])
  const [sortOrder, setSortOrder] = useState("MemberID")
  const [states, setStates] = useState([])
  const [estates, setEstates] = useState([])
  const [postalCodes, setPostalCodes] = useState([])

  const [geography, setGeography] = useState('state')
  const [geographyFilter, setGeographyFilter] = useState("ALL")
  const [geographyFilterArray, setGeographyFilterArray] = useState()

  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingGen, setIsLoadingGen] = useState(false)
  const [shareCertList, setShareCertList] = useState([])
  const [seperatedColumn, setSeperatedColumn] = useState([])
  const [selectedColumn, setSelectedColumn] = useState([])
  const [arrangeTable, setArrangeTable] = useState(false)
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [filterState, setFilterState] = useState(false)
  const [selectAll, setSelectAll] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [totalRecords, setTotalRecords] = useState(null)
  const [errors, setErrors] = useState({memIdRange:"", transDate:"", existYear:"", certStatusDate:"", ackCertStatus:"",})
  const [emptyMessage, setEmptyMessage] = useState("No Share Certificates found.")
  const [certStatus, setCertStatus] = useState("G")
  const [distinctYears, setDistinctYears] = useState([])
  const [existYear, setExistYear] = useState("")
  const [issuedDateArr, setIssuedDateArr] = useState([])
  const [issuedDate, setIssuedDate] = useState("")
  const [existDialogOpen, setExistDialogOpen] = useState(false)
  const [ackCertStatus, setAckCertStatus] = useState()
  const [response, setResponse] = useState("");
  const [success, setSuccess] = useState(false);
  const [lastCertType, setLastCertType] = useState(false)
  const [lastCertStatus, setLastCertStatus] = useState(false)
  const [lastAckCertStatus, setLastAckCertStatus] = useState(false)
  const [lastTransDate, setLastTransDate] = useState()

  const geographyArray = [
    { name: "State", code: "state" },
    { name: "Estate", code: "estate" },
    { name: "Postal Code", code: "postal" }
]

  const certStatusArr = [
    { name: "Collected", code: "C" },
    { name: "Not Collected", code: "G" },
    { name: "Surrended", code: "S" },
    { name: "Statutory Declaration", code: "D" },
    { name: "Certificate Issued", code: "certIssued" },
    { name: "Cancelled", code: "X" }
  ]

  const ackCertStatusArr = [
    { name: "Collected", code: "C", visible: true },
    { name: "Surrended", code: "S", visible: false },
    { name: "Statutory Declaration", code: "D", visible: false },
    { name: "Cancelled", code: "X", visible: true},
  ]

  let api_count = 0
  useEffect(() => {
    const fetchData = async () => {
      if (api_count === 0) {
          const [response1, response2] = await Promise.all([CustomAxios.get('oasis/initialData/'), CustomAxios.get('oasis/form_details?type=shareCert')])
          if(response1.data) {
            api_count++
            setPostalCodes(response1.data.postal_codes)
          }
          if(response2.data) {
            setEstates(response2.data.estateList)
            setStates(response2.data.stateList)
            setDistinctYears(response2.data.distinctShareCertYears)
            setIssuedDateArr(response2.data.distinctShareCertDates)
            setExistYear(response2.data.distinctShareCertYears[0])
            setIssuedDate(response2.data.distinctShareCertDates[0])
          }
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    /// onchange of geograpgy we are restting the selected option of geograph array filter
    setGeographyFilter('')
    if (geography == 'state') {
        setGeographyFilterArray(states.map(state => state.statename))
    }
    else if (geography == 'estate') {
        setGeographyFilterArray(estates.map(estate => estate.estatename))
    }
    else if (geography == 'postal') {
        setGeographyFilterArray(postalCodes)
    }

}, [states, geography])

const handleSubmit = async(e) => {
    
    e.preventDefault()
    
    console.log("Trans Date: ", transDate)

    if (!parseInt(memIdRange[0]) || !parseInt(memIdRange[1]) || parseInt(memIdRange[0]) > parseInt(memIdRange[1]) || (certType === 'New'?!transDate || transDate.$d == "Invalid Date": (!transDate || transDate.$d == "Invalid Date") || (!existYear || existYear.$d == "Invalid Date"))) {
      if(parseInt(memIdRange[0]) > parseInt(memIdRange[1]))
        setErrors(prev => ({...prev, memIdRange: "Membership Range Start Id should be smaller than or equal to End Id"}))
      else if(!parseInt(memIdRange[0]) || !parseInt(memIdRange[1]))
        setErrors(prev => ({...prev, memIdRange: "Please enter valid membership range"}))
      else
        setErrors(prev => ({...prev, memIdRange: ""}))
      if(!transDate || transDate.$d == "Invalid Date")
        setErrors(prev => ({...prev, transDate: !transDate?"Please choose a trans date":"Invalid Date"}))
      else
        setErrors(prev => ({...prev, transDate: ""}))
      if(!existYear || existYear.$d == "Invalid Date")
        setErrors(prev => ({...prev, existYear: !existYear?"Please choose a year":"Invalid Date"}))
      else
        setErrors(prev => ({...prev, existYear: ""}))

      return
    }
    
    setDialogOpen(false)
    setLastCertType(certType)
    setIsLoading(true)
   
    const payload = {
      certType: certType,
      memIdRange: memIdRange,
      state: geography == 'state' ? geographyFilter!=="ALL"?geographyFilter:"" : "",
      estate: geography == 'estate' ? geographyFilter!=="ALL"?geographyFilter:"" : "",
      postal_code: geography == 'postal' ? geographyFilter!=="ALL"?geographyFilter:"" : "",
      sortOrder: sortOrder
    };

    if(certType === 'New') {

      const currentDateTime = new Date()
      const formattedDate = transDate?.set('hour', currentDateTime.getUTCHours().toString().padStart(2, '0')).set('minute', currentDateTime.getUTCMinutes().toString().padStart(2, '0')).set('second', currentDateTime.getUTCSeconds().toString().padStart(2, '0')).set('millisecond', currentDateTime.getUTCMilliseconds().toString().padStart(3, '0'));
      console.log("Formatted Trans Date: ", transDate)
      console.log("Formatted Date: ", formattedDate)

      payload['transDate'] = formattedDate?.format("YYYY-MM-DDTHH:mm:ss.SSS")
      setLastTransDate( payload['transDate'])

      setColumnVisible([
        { field: "memberid", header: "Member ID", visible: true, filterField: "memberid", filter: true, filterPlaceholder: "Search", frozen: true, filterElement: CustomFilter, datatype: "numeric" },
        { field: "certId", header: "Certificate ID", visible: true, filter: true, filterPlaceholder: "Search", frozen: true, filterElement: CustomFilter, filterField: "certId", datatype: "numeric" },
        { field: "membername", header: "Member Name", visible: true, filterField: "membername", filter: true, filterPlaceholder: "Search", frozen: true, filterElement: CustomFilter },
        { field: "photo", header: "Photo", visible: true, filterField: "photo", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
        { field: "issuedStatus", header: "Issued Status", visible: true, filterField: "issuedStatus", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
        { field: "issuedDate", header: "Issued Date", visible: true, filterField: "issuedDate", filter: true, filterPlaceholder: "Search", frozen: true, filterElement: CustomFilter, body: e => e.issuedDate ? dayjs.utc(e.issuedDate)?.format("DD-MM-YYYY") : "" },
        { field: "sharebalance", header: "Current Share Balance", visible: true, filterField: "sharebalance", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter, body: e => parseFloat(e.sharebalance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') },
        { field: "dueAmt", header: "Due Amount", visible: true, filterField: "dueAmt", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter, body: e => parseFloat(e.dueAmt).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') },
        { field: "oldicno", header: "Old IC No.", visible: true, filterField: "oldicno", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
        { field: "newicno", header: "New IC No.", visible: true, filterField: "newicno", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
        { field: "mobileno", header: "Mobile No", visible: true, filterField: "mobileno", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
        { field: "add1", header: "Add1", visible: true, filterField: "add1", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
        { field: "add2", header: "Add2", visible: true, filterField: "add2", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
        { field: "add3", header: "Add3", visible: true, filterField: "add3", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
        { field: "add4", header: "Add4", visible: true, filterField: "add4", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
        { field: "estate", header: "Estate", visible: true, filterField: "estate", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
        { field: "state", header: "State", visible: true, filterField: "state", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
      ])
    } else {
      payload['existYear'] = existYear
      payload['certStatus'] = certStatus
      payload['issuedDate'] = issuedDate.split('-').reverse().join('-')

      setColumnVisible([
        { field: "memberid", header: "Member ID", visible: true, filterField: "memberid", filter: true, filterPlaceholder: "Search", frozen: true, filterElement: CustomFilter, datatype: "numeric" },
        { field: "certId", header: "Certificate ID", visible: true, filter: true, filterPlaceholder: "Search", frozen: true, filterElement: CustomFilter, filterField: "certId", datatype: "numeric" },
        { field: "membername", header: "Member Name", visible: true, filterField: "membername", filter: true, filterPlaceholder: "Search", frozen: true, filterElement: CustomFilter },
        { field: "sharebalance", header: "Current Share Balance", visible: true, filterField: "sharebalance", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter, body: e => parseFloat(e.sharebalance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') },
        { field: "issueddate", header: "Issued Date", visible: true, filterField: "issueddate", filter: true, filterPlaceholder: "Search", frozen: true, filterElement: CustomFilter, body: e => e.issueddate ? dayjs.utc(e.issueddate)?.format("DD-MM-YYYY") : "" },
        { field: "certificatestatus", header: "Certificate Status", visible: true, filterField: "certificatestatus", filter: true, filterPlaceholder: "Search", frozen: true, filterElement: CustomFilter, body: e => e.certificatestatus == 'C'?'Collected':e.certificatestatus == 'G'?'Not Collected':e.certificatestatus == 'S'?'Surrended':e.certificatestatus == 'D'?'Statutory Declaration':e.certificatestatus == 'X'?'Cancelled':'' },
        { field: "printStatus", header: "Print Status", visible: true, filterField: "printStatus", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
        { field: "newicno", header: "New IC No.", visible: true, filterField: "newicno", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
        { field: "oldicno", header: "Old IC No.", visible: true, filterField: "oldicno", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
        { field: "collecteddate", header: "Collected Date", visible: true, filterField: "collecteddate", filter: true, filterPlaceholder: "Search", frozen: true, filterElement: CustomFilter, body: e => e.collecteddate ? dayjs.utc(e.collecteddate)?.format("DD-MM-YYYY") : "" },
        { field: "surrendeddate", header: "Surrended Date", visible: true, filterField: "surrendeddate", filter: true, filterPlaceholder: "Search", frozen: true, filterElement: CustomFilter, body: e => e.surrendeddate ? dayjs.utc(e.surrendeddate)?.format("DD-MM-YYYY") : "" },
        { field: "statdecldate", header: "Stat Decl Date", visible: true, filterField: "statdecldate", filter: true, filterPlaceholder: "Search", frozen: true, filterElement: CustomFilter, body: e => e.statdecldate ? dayjs.utc(e.statdecldate)?.format("DD-MM-YYYY") : "" },
        { field: "mobileno", header: "Mobile No", visible: true, filterField: "mobileno", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
        { field: "add1", header: "Add1", visible: true, filterField: "add1", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
        { field: "add2", header: "Add2", visible: true, filterField: "add2", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
        { field: "add3", header: "Add3", visible: true, filterField: "add3", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
        { field: "add4", header: "Add4", visible: true, filterField: "add4", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
        { field: "estate", header: "Estate", visible: true, filterField: "estate", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
        { field: "state", header: "State", visible: true, filterField: "state", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
      ])
      setLastCertStatus(certStatus);
    }

    const response = await CustomAxios.post(`oasis/share_certificate/`, payload)
    // console.log("Response: ", response.data.shareCertList)
    if(response.data.shareCertList!="No Records Found for this Criteria") {
      setShareCertList(response.data.shareCertList)
    } else {
        setShareCertList([])
        setEmptyMessage(response.data.shareCertList)
    }
    
    setIsLoading(false)
    
    setSelectAll(false);
    setSelectedMembers([])
    // setMemIdRange([0, 0])
    setSortOrder("MemberID")
    setGeography('state')
    setErrors(({memIdRange:"", transDate:"", existYear:""}))
    setResponse("")
}

const handleGenerateShareCert = async () => {
    setIsLoading(true)
    setIsLoadingGen(true)
    const username = localStorage.getItem('username');

    try {
        const response = await CustomAxios.post(`oasis/generate_share_cert/${username}`, {
          'shareCertList': selectedMembers,
          'issuedDate': lastTransDate
        });

        if (response.status === 200) {
          const updatedShareCerts = response.data.shareCertList;
          const newShareCertList = shareCertList.map(cert => {
            const updatedCert = updatedShareCerts.find(uCert => uCert.memberid === cert.memberid);
            return updatedCert || cert;
          });

          setShareCertList(newShareCertList);

          const dates = [...issuedDateArr, dayjs(lastTransDate).format("DD-MM-YYYY")];
          dates.sort((a, b) => dayjs(b, "DD-MM-YYYY") - dayjs(a, "DD-MM-YYYY"));
          setIssuedDateArr(dates);

          setResponse('newCertificatesCreatedSuccessfully');
          setSuccess(true);
          setSelectAll(false);
          setSelectedMembers([])
        } else {
          console.log('Request failed with status:', response.status);
          setShareCertList([]);
          setEmptyMessage('Failed to update certificates');
        }
    } catch (error) {
      console.error('Error during API call:', error.message);
    } finally {
        setIsLoading(false);
        setIsLoadingGen(false)
    }
}

useEffect(() => {
  if (success) {
    const timer = setTimeout(() => {
      setSuccess(false);
    }, 5000);
    return () => clearTimeout(timer);
  }
}, [success]);

const exportExcel = () => {
  let headers = columnVisible.map(col => ({ header: col.header, key: col.field }));
  headers = [{ header: "SN", key: "SN" }, ...headers];

  const data = (lastCertStatus === 'certIssued'?selectedMembers : shareCertList).map((item, index) => {
      return { SN: index + 1, ...item };
  });

  const headersBold = headers.map(({ header }) => ({
      v: header,
      t: 's',
      s: {
          font: { bold: true, size: 10 }
      }
  }));

  const additionalRows = [
      ['','KOPERASI SERBAGUNA KEBANGSAAN BERHAD (4391)'],
      [],
      ['','Share Certificate List'],
      ['',`Mno Range : ${memIdRange[0]} To ${memIdRange[1]}`],
      lastCertType === 'New'?['', `Issued Date(If Print Status is as 'Y'): ${transDate?.format('DD-MM-YYYY')}`]:['', `Issued Year : ${existYear}`],
      ['',`Report Date/Time : ${dayjs(new Date()).format('DD-MM-YYYY HH:MM:ss')}`],
      ['', `State : ${geographyFilter?geography == 'state' && geographyFilter:'ALL'}    Estate : ${geography == 'estate' && geographyFilter?geographyFilter:'ALL'}    Postal Code : ${geography == 'postal' && geographyFilter?geographyFilter:'ALL'}`],
      []
  ];

  // Convert array of objects to array of arrays (2D array) for the data section
  const dataRows = data.map(obj => headers.map(h => {
      let value;
      const dateRelatedKeys = ["issueddate","collecteddate","surrendeddate","statdecldate"]
      if(dateRelatedKeys.includes(h.key)){
          value = obj[h.key] ? dayjs.utc(obj[h.key])?.format("DD-MM-YYYY") : ""
      } 
      else if(h.key === 'certificatestatus')
        value = obj[h.key] == 'C'?'Collected':obj[h.key] == 'G'?'Not Collected':obj[h.key] == 'S'?'Surrended':obj[h.key] == 'D'?'Statutory Declaration':obj[h.key] == 'X'?'Cancelled':''
      else if(h.key === 'oldicno' || h.key === 'newicno')
        value = obj[h.key]?obj[h.key]:""
      else
        value = obj[h.key]
      return ({
      v: value,
      t: typeof value === 'number' ? 'n' : 's',
      s: {
          alignment: { horizontal: typeof value === 'number' ? 'right' : 'left' },
          font: { size: 8, name: 'Times New Roman' }
      }
  })
}));

  const dataArray = [...additionalRows, headersBold, ...dataRows];

  // Convert data to worksheet
  const ws = XLSX.utils.aoa_to_sheet(dataArray);

  // Create workbook and add the worksheet
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  // Generate XLSX file and trigger download
  XLSX.writeFile(wb, 'Share Certificate List.xlsx');
};

const handleUpdateCertStatus = async (e) => {
  
  e.preventDefault()

  if(!ackCertStatus || !certStatusDate || certStatusDate.$d == "Invalid Date") {
    if(!certStatusDate || certStatusDate.$d == "Invalid Date")
      setErrors(prev => ({...prev, certStatusDate: !certStatusDate?"Please choose a date":"Invalid Date"}))
    else
      setErrors(prev => ({...prev, certStatusDate: ""}))
    if(!ackCertStatus)
      setErrors(prev => ({...prev, ackCertStatus: "Please choose a status"}))
    else
      setErrors(prev => ({...prev, ackCertStatus: ""}))
    return
  }

  const username = localStorage.getItem('username');
  const currentDateTime = new Date()
  const formattedDate = dayjs(certStatusDate)?.set('hour', currentDateTime.getUTCHours().toString().padStart(2, '0')).set('minute', currentDateTime.getUTCMinutes().toString().padStart(2, '0')).set('second', currentDateTime.getUTCSeconds().toString().padStart(2, '0')).set('millisecond', currentDateTime.getUTCMilliseconds().toString().padStart(3, '0'));
  console.log("Formatted Date: ", formattedDate?.format("YYYY-MM-DDTHH:mm:ss.SSS"))
     
  const payload = {
    'ackCertStatus': ackCertStatus,
    'transDate': formattedDate?.format("YYYY-MM-DDTHH:mm:ss.SSS"),
    'certList': selectedMembers,
    'updatedby': username
  }
  const response = await CustomAxios.post('oasis/update_share_certificate/', payload)

  if (response.status === 200 && response.data) {

    setResponse('statusUpdatedSuccessfully')
    setSuccess(true)
    setExistDialogOpen(false)

    let updatedShareCertList, updatedDateObj, dateKey = (ackCertStatus === 'C'?"collecteddate":ackCertStatus === 'S'?"surrendeddate":(ackCertStatus === 'D'||ackCertStatus === 'X')&&"statdecldate")
      if(selectedMembers.length !== shareCertList.length){
          updatedShareCertList = shareCertList.map(shareCert => {
             let selectedMember = selectedMembers.filter(selectedMember => selectedMember.memberid==shareCert.memberid)
             if(selectedMember.length>0) {

                updatedDateObj = {...shareCert}
                updatedDateObj[dateKey] = !shareCert[dateKey]?`${formattedDate?.format("YYYY-MM-DD")}`: shareCert[dateKey]
                updatedDateObj['certificatestatus'] = ackCertStatus

                return updatedDateObj
             }
             else
                return shareCert
        })
      }
      else {
          updatedShareCertList = shareCertList.map(shareCert => {

            updatedDateObj = {...shareCert}
            updatedDateObj[dateKey] = !shareCert[dateKey]?`${formattedDate?.format("YYYY-MM-DD")}`: shareCert[dateKey]
            updatedDateObj['certificatestatus'] = ackCertStatus

            return updatedDateObj
          })
      }
      setShareCertList(updatedShareCertList)
      setLastAckCertStatus(ackCertStatus)
      setCertStatusDate(dayjs())
      setAckCertStatus("")
      setSelectAll(false);
      setSelectedMembers([])
  }
}

const CustomFilter = (options) => {

  return (
      <InputText style={{ width: "182px", height: "36px" }} placeholder="search" value={options.value} onChange={(e) => options.filterApplyCallback(e.target.value,)} />
  );
};

const [columnVisible, setColumnVisible] = useState([
  { field: "memberid", header: "Member ID", visible: true, filterField: "memberid", filter: true, filterPlaceholder: "Search", frozen: true, filterElement: CustomFilter, datatype: "numeric" },
  { field: "certId", header: "Certificate ID", visible: true, filter: true, filterPlaceholder: "Search", frozen: true, filterElement: CustomFilter, filterField: "certId", datatype: "numeric" },
  { field: "membername", header: "Member Name", visible: true, filterField: "membername", filter: true, filterPlaceholder: "Search", frozen: true, filterElement: CustomFilter },
  { field: "photo", header: "Photo", visible: true, filterField: "photo", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
  { field: "issuedStatus", header: "Issued Status", visible: true, filterField: "issuedStatus", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
  { field: "issuedDate", header: "Issued Date", visible: true, filterField: "issuedDate", filter: true, filterPlaceholder: "Search", frozen: true, filterElement: CustomFilter,  body: e => e.issuedDate ? dayjs.utc(e.issuedDate)?.format("DD-MM-YYYY") : "" },
  { field: "sharebalance", header: "Current Share Balance", visible: true, filterField: "sharebalance", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter, body: e => parseFloat(e.sharebalance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') },
  { field: "dueAmt", header: "Due Amount", visible: true, filterField: "dueAmt", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter, body: e => parseFloat(e.dueAmt).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') },
  { field: "oldicno", header: "Old IC No.", visible: true, filterField: "oldicno", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
  { field: "newicno", header: "New IC No.", visible: true, filterField: "newicno", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
  { field: "mobileno", header: "Mobile No", visible: true, filterField: "mobileno", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
  { field: "add1", header: "Add1", visible: true, filterField: "add1", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
  { field: "add2", header: "Add2", visible: true, filterField: "add2", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
  { field: "add3", header: "Add3", visible: true, filterField: "add3", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
  { field: "add4", header: "Add4", visible: true, filterField: "add4", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
  { field: "estate", header: "Estate", visible: true, filterField: "estate", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
  { field: "state", header: "State", visible: true, filterField: "state", filter: true, filterPlaceholder: "Search", frozen: false, filterElement: CustomFilter },
])

const [filters, setFilters] = useState(() => {
  let filter_obj = {}
  filter_obj['global'] = { value: null, matchMode: FilterMatchMode.CONTAINS }
  columnVisible.map((obj) => {
      filter_obj[obj.field] = { value: null, matchMode: FilterMatchMode.CONTAINS }
  })
  return filter_obj
});

const onColumnChange = (e) => {
  let column = [...columnVisible]
  let _selectedCategories = [...seperatedColumn];
  if (e.checked) {
      //console.log(e.value)
      let deselect = column.filter(item => item.header == e.value.header).map(item => { item.visible = true; return item })
      //console.log(column)
      deselect = [...column, ...deselect];
      deselect = [...new Set(deselect)]
      //console.log(deselect)
      setSelectedColumn(deselect)
      _selectedCategories.push(e.value);
  }
  else {
      //console.log(e.value)
      let deselect = column.filter(item => item.header == e.value.header).map(item => { item.visible = false; return item })
      //console.log(column)
      deselect = [...column, ...deselect];
      deselect = [...new Set(deselect)]
      //console.log(deselect)
      setSelectedColumn(deselect)
      _selectedCategories = _selectedCategories.filter(category => category.header !== e.value.header);


  }
  setSeperatedColumn(_selectedCategories)

};
const applyFilter = () => {
  let columns = [...columnVisible]

  setColumnVisible(selectedColumn)
  setArrangeTable(false)
}

const clearFilter = () => {

  let filter_obj = {}
  filter_obj['global'] = { value: null, matchMode: FilterMatchMode.CONTAINS }
  columnVisible.map((obj) => {
      filter_obj[obj.field] = { value: null, matchMode: FilterMatchMode.CONTAINS }
  })
  setFilters(filter_obj)
  setGlobalFilterValue('')
  setFilterState(false)
}

const globalFilterFields = columnVisible.map((obj) => { return obj.field })

const onGlobalFilterChange = (e) => {
  const value = e.target.value;
  let _filters = { ...filters };

  _filters['global'].value = value;
  setFilters(_filters);
  setGlobalFilterValue(value);
};

// Effect to handle re-selection after data is reset
useEffect(() => {
  if (lastCertType === 'Existing' && lastCertStatus === 'certIssued') {
      setSelectedMembers(prevSelection => {
        return shareCertList.filter(dataItem => prevSelection.some(sel => sel.memberid === dataItem.memberid));
      });
  }
}, [shareCertList]); // Re-run this effect when data changes

const onSelectAllChange = (event) => {
  const selectAll = event.checked;

  if (selectAll) {
    // Filter the shareCertList based on the globalFilterValue or filters
    let filteredmembers = shareCertList.filter((member) => {
      return globalFilterFields.some((field) => {
        return member[field]
          ?.toString()
          .toLowerCase()
          .includes(globalFilterValue.toLowerCase());
      });
    });
    if (lastCertType === 'New') {
        filteredmembers = filteredmembers.filter((member) => {
          return member.issuedStatus === 'N'
        });
    }
    else if (lastCertType === 'Existing' && lastCertStatus !== 'certIssued') {
        filteredmembers = filteredmembers.filter((member) => {
          return member.certificatestatus === lastCertStatus
        });
    }

    setSelectAll(true);
    setSelectedMembers(filteredmembers); // Only select the filtered items
  } else {
    setSelectAll(false);
    setSelectedMembers([]);
  }
};
const onSelectionChange = (event) => {
  const value = lastCertType === 'New' ? event.value.filter(member => member.issuedStatus === 'N') : (lastCertType === 'Existing' && lastCertStatus !== 'certIssued') ? event.value.filter(member => member.certificatestatus === lastCertStatus) : event.value
  setSelectedMembers(value);
  //console.log(selectedMembers)
  setSelectAll(value.length === totalRecords);
};

const printShareCertificate = async () => {
  try {
      // Fetch the PDF receipt
      const response = await CustomAxios.post(`oasis/print_share_certificate/`,selectedMembers,{ responseType: 'blob' });
      if (response.data) {
        let updatedShareCertList
          if(selectedMembers.length !== shareCertList.length) {
              updatedShareCertList = shareCertList.map(shareCert => {
                 let selectedMember = selectedMembers.filter(selectedMember => selectedMember.memberid==shareCert.memberid)
                 if(selectedMember.length>0)
                    return {...shareCert, printStatus:'Y'}
                 else
                    return shareCert
            })
          }
          else {
              updatedShareCertList = shareCertList.map(shareCert => {
                return {...shareCert, printStatus:'Y'}
              })
          }
          setShareCertList(updatedShareCertList)
          // Create a URL for the PDF blob
          // console.log("Created", response.data)
          const fileURL = URL.createObjectURL(response.data);

          // Open the PDF in a new window/tab
          const win = window.open(fileURL, '_blank');

          // Optionally, automatically trigger the print dialog in the new window
          win.onload = () => {
              win.focus();
              win.print();
          };
      }
  } catch (error) {
      console.error("Error fetching the share certificate: ", error);
  }
};

const header = (
  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
  <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box>

          <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText style={{ width: "655px", height: "36px" }} placeholder="Search" value={globalFilterValue} onChange={onGlobalFilterChange} />
          </span>
      </Box>
      <Box sx={{ color: "#64748B", width: "178px", padding: "12px", display: "flex", alignItems: "center", justifyContent: "center", marginLeft: "16px", alignSelf: "end", cursor: "pointer", textTransform: "none", gap: "8px" }} onClick={clearFilter}>
          <ClearAllIcon sx={{ fontSize: "24px", color: "#CBD5E1" }} />
          <Typography sx={{ fontSize: "16px", fontWeight: "400", lineHeight: "24px", color: "#CBD5E1" }}></Typography>
          Clear Filter
      </Box>
  </Box>
  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: "16px", alignSelf: 'end' }}>
       {(lastCertType === 'Existing' && lastCertStatus === 'certIssued') && <Button type="button" disabled={selectedMembers.length===0 || shareCertList.length === 0} severity="success" rounded onClick={printShareCertificate} data-pr-tooltip="XLS" >Download Certificate</Button>}
       {lastCertType === 'Existing' && (lastCertStatus === 'C' || lastCertStatus === 'G') && <Button type="button" disabled={selectedMembers.length===0 || shareCertList.length === 0} severity="success" rounded onClick={() => {setCertStatusDate(dayjs());setAckCertStatus("");setExistDialogOpen(true)}} data-pr-tooltip="XLS" >Update Certificate Status</Button>}
      <Box sx={{ padding: "12px" }}> {filterState ? <FilterAltOffIcon sx={{ color: "#64748B", fontSize: "24px", cursor: "pointer" }} onClick={() => setFilterState(false)} /> : <FilterAltIcon sx={{ color: "#64748B", fontSize: "24px", cursor: "pointer" }} onClick={() => setFilterState(true)} />}</Box>
       <Button type="button" disabled={lastCertStatus === 'certIssued'?selectedMembers.length===0:shareCertList.length === 0} severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" ><DownloadIcon sx={{ color: "#64748B", fontSize: "24px" }} /></Button>
      <Box sx={{ padding: "12px" }}><TableChartIcon sx={{ color: "#64748B", fontSize: "24px", cursor: "pointer" }} onClick={() => { setArrangeTable(true); setSeperatedColumn(columnVisible) }} /> </Box>

  </Box>
</Box>
);

  const isMobile = useMediaQuery({ maxWidth: 600 })
  return (
    <Box sx={{ backgroundColor: isMobile && "#F8FAFC", height: isMobile ? "100vh" : "calc(100vh - 72px)", width: "100%", overflow: "auto",marginTop:"60px" }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "20px", height: { sm: 'initial', padding: "0px 20px 0px 20px" }, width: "100%" }}>
        <Box sx={{ top: "72px", left: "304px", padding: "8px 0px 8px 0px", display: "flex", justifyContent: "space-between", borderBottom: "1px solid #CBD5E1", alignItems: "center", height: "84px" }}>
          <Box sx={{ height: "48px", padding: "8px 0px 8px 0px", gap: "12px", display: "flex", alignItems: "center" }}>
            <Box sx={{ width: "32px", height: "32px" }}>
              <ArticleIcon sx={{ width: "32px", height: "32px", color: "#288d2c" }} />
            </Box>
            <Typography sx={{ fontFamily: "inter", fontSize: "20px", fontWeight: "600", lineHeight: "28px", color: "#101010", width: "100%" }}>Share Certificate {lastCertType && "(" + (lastCertType) + ")"}</Typography>
          </Box>
          {(response === "statusUpdatedSuccessfully" || response === "newCertificatesCreatedSuccessfully") && success && (
            <Box
            sx={{
              padding: "30px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              position: "fixed",
              top: 30,
              left: 0,
              right: 0,
              zIndex: 1000
            }}
          >
            <Grid
              item
              xs={12}
              sm={5}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                maxWidth: "580px", // Ensures the box doesn't stretch too wide
                background: "rgba(220, 252, 231, 0.8)", // Semi-transparent green background
                border: "2px solid #E7EFE9",
                    borderRadius: "4px",
                    borderRadius: "4px",
                    display: "flex",
                borderRadius: "4px",
                    display: "flex",
                gap: "16px",
                padding: "10px",
                position: "relative", // Positioned relative to its normal position
              }}
            >
              <IconButton onClick={() => setSuccess(false)}>
                <DoneIcon sx={{ width: "24px", height: "24px" }} />
              </IconButton>
              <Typography
                sx={{
                  fontFamily: "Inter", // Ensuring correct font family usage
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "24px",
                  color: "#16A34A",
                  flexGrow: 1, // Ensures the text can expand as needed within the flex item
                }}
              >
                {response === "statusUpdatedSuccessfully" ?
                 (lastAckCertStatus === 'C' ? "Certificates Set as Collected!" :
                 lastAckCertStatus === 'S' ? "Certificates Surrendered!" :
                 lastAckCertStatus === 'D' ? "Certificates Missing! Statutory Declaration Done!" :
                 lastAckCertStatus === 'X' ? "Certificates Cancelled!" : "") :
                "New Certificate(s) Generated Successfully" }
              </Typography>
              <IconButton onClick={() => setSuccess(false)}>
                <ClearIcon sx={{ width: "24px", height: "24px" }} />
              </IconButton>
            </Grid>
          </Box>
          )}
          <Box sx={{ display: "flex", gap: "20px", justifyContent: "flex-end" }}> 
            {lastCertType === 'New' &&
                <MuiButton
                variant="contained"
                onClick={() => handleGenerateShareCert()} 
                value={isLoadingGen ? <CircularProgress size={24} color="inherit" /> : 'Generate Share Certificate'}
                disabled={selectedMembers.length===0 || shareCertList.length === 0}
                btnsx={{
                  width: "240px",
                  cursor: (selectedMembers.length===0 || shareCertList.length === 0) ? 'not-allowed' : 'pointer',
                  '&:hover': {
                    cursor: (selectedMembers.length===0 || shareCertList.length === 0) ? 'not-allowed' : 'pointer'
                  }
                }}
              />}
            <MuiButton
              variant="contained"
              onClick={() => setDialogOpen(true)} 
              value="View Share Certificate"
              btnsx={{
                width: "240px",
              }}
            />
          </Box>
        </Box>
      </Box>
                {isLoading ?
                <Stack rowGap="10px" sx={{ width: "100%", height: "87vh", alignItems: "center", justifyContent: "center" }}>
                    <Typography>Please Wait</Typography>
                    <ProgressBar mode="indeterminate" style={{ width: "25%", height: '6px' }} />
                </Stack>
                :
                <>
                    <Box sx={{ height: "calc(100vh-84px)" }}>

                        <Box className="card">
                            <DataTable value={shareCertList} header={header} rows={15} style={{ backgroundColor: "#F1F5F9", position: "relative" }} scrollable removableSort filters={filters} paginator filterDisplay={filterState ? "row" : ""} columnResizeMode="expand" resizableColumns
                                emptyMessage={emptyMessage} selection={selectedMembers} selectionMode="checkbox" onSelectionChange={onSelectionChange} selectAll={selectAll} filterIcon={false} onSelectAllChange={onSelectAllChange} globalFilterFields={globalFilterFields} >
                                <Column styleClass="custom-column-style" selectionMode="multiple" headerStyle={{ width: "100px", background: "#F1F5F9" }} frozen />
                                <Column header="SN" headerStyle={{ width: '500px' }} body={(data, options) => options.rowIndex + 1} frozen></Column>
                                {columnVisible.filter(col => col.visible).map(col => <Column className="custom-column-style" style={{ width: "500px" }} field={col.field} header={col.header} body={col.body} alignFrozen="left" filter={col.filter} sortable dataType={col.datatype} filterField={col.filterField} filterPlaceholder={col.filterPlaceholder} frozen={col.frozen} />)}
                            </DataTable>
                        </Box>
                    </Box>
                    <Dialog visible={arrangeTable} style={{ width: '521px', boxShadow: "0px 0px 34px 0px rgba(100, 116, 139, 0.50)", background: "#F8FAFC", borderRadius: "0px", }} className="custombar1" draggable={false} position="center" modal={true} closable={false} resizable={false} >
                        <Box style={{ width: '521px', boxShadow: "0px 0px 34px 0px rgba(100, 116, 139, 0.50)", background: "#F8FAFC", padding: "30px 30px 0px 30px", borderRadius: "0px", display: "flex", flexDirection: "column", gap: "24px", }}>
                            <Box sx={{ display: "flex", gap: "16px", alignItems: "center", background: "#F8FAFC" }}>
                                <TableChartIcon sx={{ fontSize: "24px", color: "#288d2c" }} />
                                <Typography sx={{ fontSize: "18px", fontWeight: 600, lineHeight: "28px" }}>Table Column Configure</Typography>
                            </Box>
                            <Box>


                                <span className="p-input-icon-left">
                                    <i className="pi pi-search" />
                                    <InputText style={{ width: "461px", height: "36px" }} placeholder="Search" onChange={onGlobalFilterChange} />
                                </span>

                            </Box>
                            <Box sx={{ height: "461px", overflowY: "scroll", display: "flex", flexDirection: "column", gap: "20px" }}>

                                {columnVisible.map((column, ind) => {
                                    return (

                                        <div key={column.header} style={{ gap: "8px", display: "flex", alignItems: "center" }}>
                                            <Checkbox inputId={column.header} value={column} onChange={onColumnChange} disabled={column.header == "Member #" || column.header == "MemberName"} checked={column.visible} />
                                            <label htmlFor={column.header} className="ml-2">
                                                {column.header}
                                            </label>
                                        </div>

                                    );
                                })}

                            </Box>
                            <Box sx={{ display: "flex", gap: "16px", alignItems: "center", background: "#F1F5F9", paddingTop: "24px", paddingBottom: "24px", justifyContent: "space-between", marginLeft: "-30px", marginRight: "-30px", }}>

                                <Button variant="contained" sx={{ background: "#288d2c", "&:hover": { background: "#22c54e" }, fontSize: "16px", fontWeight: "400", lineHeight: "24px", padding: "12px", textTransform: "none", width: "138px", height: "48px", marginLeft: "30px" }} startIcon={<CheckCircleOutlineIcon />} onClick={applyFilter} >Apply</Button>
                                <Button variant="outlined" sx={{ borderColor: "#64748B", color: "#64748B", "&:hover": { borderColor: "#64748B" }, fontSize: "16px", fontWeight: "400", lineHeight: "24px", padding: "12px", marginRight: "30px" }} startIcon={<ClearIcon sx={{ color: "#64748B", fontSize: "24px" }} />} onClick={() => setArrangeTable(false)}>Clear</Button>


                            </Box>
                        </Box>
                    </Dialog >
                </>
            }
      <Dialog
        visible={dialogOpen}
        style={{ width: "50%", height: certType === 'New'?errors.memIdRange && errors.transDate? "50%" : errors.memIdRange || errors.transDate?"47%":"43%": errors.memIdRange && errors.transDate? "50%" : "48%", position: "relative" }}
        onHide={() => setDialogOpen(false)}
      >
        <Box>
        <form>
          <Box sx={{ padding: "5px",display: 'flex', flexDirection: 'column', gap: '25px', border: '1px solid black', margin: '5px' }}>
            <Typography sx={{ color: "black", fontWeight: "600" }}>
              Criteria to Process / View the Cert.
            </Typography>
            <Box sx={{ width: "100%", display: "flex", gap: "20px", alignItems: "center", justifyContent: "space-between", }}>
              <Box sx={{ display: "flex", gap: "20px",  }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: "2px" }}>
                  <RadioButton
                    style={{ display: "flex", alignItems: "center" }}
                    inputId="type1"
                    name="certType"
                    value="New"
                  onChange={(e) => setCertType(e.target.value)}
                  checked={certType === "New"}
                  required
                  />
                  <label htmlFor="type1" className="ml-2">
                    NEW Certificate
                  </label>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "2px" }}
                  >
                    <RadioButton
                      style={{ display: "flex", alignItems: "center" }}
                      inputId="type2"
                      name="certType"
                      value="Existing"
                    onChange={(e) => setCertType(e.target.value)}
                    checked={certType === "Existing"}
                    required
                    />
                    <label htmlFor="type2" className="ml-2">
                      View existing
                    </label>
                    {certType=='Existing' &&<><Dropdown value={certStatus} onChange={(e) => setCertStatus(e.value)} style={{ display: "flex", alignItems: "center", width:"230px", margin: "0 10px" }} options={certStatusArr} optionLabel="name" optionValue="code"
                     />
                    {certStatus=='certIssued' && <>On<Dropdown value={issuedDate} onChange={(e) => setIssuedDate(e.value)} style={{ display: "flex", alignItems: "center", width: '15 0px', margin: "0 10px"}} options={issuedDateArr}/></>}
                </>}
                  </Box>
                </Box>
                <Box>
                </Box>
              </Box>
              {/* <Box sx={{ display: "flex",flexDirection:'column' }}>
              </Box>
              <Box
                sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                <Typography>Trans Date</Typography>
                <Calendar
                  required
                  value={transDate}
                  onChange={(e) => setTransDate(e.target.value)}
                  dateFormat="dd-mm-yy"
                  placeholder="dd-mm-yyyy"
                  style={{
                    height: "36px",
                    display: "flex",
                    alignItems: "center",
                  }}
                />
              </Box> */}
              <Box
                sx={{
                  display: "flex",
                  gap: "8px",
                  flexDirection: "column",
                  width: "180px",
                }}
              >
                <DatePicker
                  label="TransDate"
                  value={transDate}
                  onChange={(e) => setTransDate(e)}
                  disableFuture
                  format="DD-MM-YYYY"
                  sx={{ backgroundColor: "white" }}
                />
              {errors.transDate !== '' && <Typography sx={{ display: "inline-block", color: "red", fontWeight: "300", fontSize: "14px" }}>{errors.transDate}</Typography>}
              </Box>
            </Box>

            {/* <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{
                      alignSelf: "stretch",
                      fontFamily: "inter",
                      fontSize: "16px",
                      fontStyle: "inter",
                      fontWeight: "600",
                      lineHeight: "24px",
                      color: "#101010",
                      width: "150px"
                    }}
                  >
                   Share Balance :
                  </Typography>
                  <InputText
                      type='number'
                      value={shareBalance}
                      onChange={(e) => setShareBalance(e.target.value)}
                      style={{
                        width: "150px",
                        borderRadius: "4px",
                        border: "1px solid var(--Light-Forms-Border, #CBD5E1)",
                        fontSize: "14px",
                      }}
                  />
                </Box> */}
            
            <Box sx={{display: 'flex', gap: "10px", border: '1px solid black', padding: '10px'}}>
            <Box sx={{ display: "flex", gap: "20px", flexDirection: "column", width: "70%" }}>
              <Box sx={{ display: "flex", alignItems: "center", flexDirection: "row", gap: '35px' }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{
                      alignSelf: "stretch",
                      fontFamily: "inter",
                      fontSize: "16px",
                      fontStyle: "inter",
                      fontWeight: "400",
                      lineHeight: "24px",
                      color: "#101010",
                      width: "150px"
                    }}
                  >
                    Membership No :
                  </Typography>
                  <InputText
                      type='number'
                      value={memIdRange[0]}
                      onChange={(e) => setMemIdRange([e.target.value, memIdRange[1]])}
                      style={{
                      width: "150px",
                      borderRadius: "4px",
                      border: "1px solid var(--Light-Forms-Border, #CBD5E1)",
                      fontSize: "14px",
                    }}
                  />
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{
                      alignSelf: "stretch",
                      fontFamily: "inter",
                      fontSize: "16px",
                      fontStyle: "inter",
                      fontWeight: "400",
                      lineHeight: "24px",
                      color: "#101010",
                      paddingRight: "10px",
                    }}
                  >
                    To :
                  </Typography>
                  <InputText
                    type='number'
                    value={memIdRange[1]}
                    onChange={(e) => setMemIdRange([memIdRange[0], e.target.value])}
                    style={{
                      padding: "8px 12px",
                      width: "150px",
                      borderRadius: "4px",
                      border: "1px solid var(--Light-Forms-Border, #CBD5E1)",
                      fontSize: "14px",
                    }}
                  />
                </Box>
              </Box>
              {errors.memIdRange !== '' && <Typography sx={{ display: "inline-block", color: "red", fontWeight: "300", fontSize: "14px" }}>{errors.memIdRange}</Typography>}
              <Box sx={{ display: "flex", gap: "8px", flexDirection: "column" }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "start", alignSelf: "start" }}>
                  <Typography sx={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px", color: "#101010" }}>Choose any one option :</Typography>

                </Box>
                <Box sx={{ display: "flex", gap: '35px' }}>
                  <Dropdown value={geography} onChange={(e) => setGeography(e.value)} style={{ display: "flex", alignItems: "center" }} options={geographyArray} optionLabel="name" optionValue="code"
                    placeholder="State" />
                  <Dropdown value={geographyFilter} onChange={(e) => setGeographyFilter(e.value)} style={{ display: "flex", alignItems: "center" }} options={geographyFilterArray}
                    placeholder="All" filter />
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex", gap: "20px", flexDirection: "column", width: "30%" }}>
            {certType=='Existing' && <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              Issued Year
              <Dropdown value={existYear} onChange={(e) => setExistYear(e.value)} style={{ display: "flex", alignItems: "center", width: '100px'}} options={distinctYears}/>
              </Box>
              }
              {errors.existYear !== '' && <Typography sx={{ display: "inline-block", color: "red", fontWeight: "300", fontSize: "14px" }}>{errors.existYear}</Typography>}
            <Box sx={{ display: "flex", flexDirection: "column", gap: certType=='New'?'10px':'5px', border: '1px solid black', padding: certType=='New'?'10px':'5px', height: '100%' }}>
              <Typography sx={{ color: "black", fontWeight: "600" }}>
                Sort Order
              </Typography>
                <Box sx={{ display: "flex", alignItems: "center", gap: "2px" }}>
                  <RadioButton
                    style={{ display: "flex", alignItems: "center" }}
                    inputId="type3"
                    name="sortOrder"
                    value="MemberID"
                  onChange={(e) => setSortOrder(e.value)}
                  checked={sortOrder === "MemberID"}
                  />
                  <label htmlFor="type1" className="ml-2">
                    Membership No
                  </label>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "2px" }}
                  >
                    <RadioButton
                      style={{ display: "flex", alignItems: "center" }}
                      inputId="type4"
                      name="sortOrder"
                      value="MemberName"
                      onChange={(e) => setSortOrder(e.value)}
                      checked={sortOrder === "MemberName"}
                    />
                    <label htmlFor="type2" className="ml-2">
                      Name
                    </label>
                  </Box>
                </Box>
              </Box>
            </Box>
            </Box>
          </Box>
          <Box sx={{display:"flex", justifyContent: "center"}}>
            <Button type="submit"  variant="contained" sx={{ marginTop: "5px",height: "32px", borderRadius: "4px", padding: "12px", display: "flex", gap: "8px", background: "#288d2c", textTransform: "none" }} onClick={handleSubmit}>View Share Certificate Details</Button>
          </Box>
        </form>
        </Box>
      </Dialog>

      <Dialog
        visible={existDialogOpen}
        style={{ width: "500px", height: "240px", position: "relative" }}
        onHide={() => setExistDialogOpen(false)}
      >
        <Box>
          <form>
          <Box sx={{ padding: "25px",display: 'flex', flexDirection: 'column', gap: '25px', border: '1px solid black', margin: '5px' }}>
            <Typography sx={{ color: "black", fontWeight: "600" }}>
            Certificate Acknowledgment
            </Typography>
            <Box sx={{ display: "flex", gap: "40px" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "180px",
                  }}
                >
                  <DatePicker
                    label="Selected Date"  // Acts as a placeholder inside the DatePicker
                    value={certStatusDate}
                    onChange={(e) => setCertStatusDate(e)}
                    disableFuture
                    format="DD-MM-YYYY"
                    sx={{ backgroundColor: "white" }}
                  />
                  {errors.certStatusDate && (
                    <Typography sx={{ color: "red", fontWeight: "300", fontSize: "14px" }}>
                      {errors.certStatusDate}
                    </Typography>
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "180px",
                  }}
                >
                  <MuiAutocomplete
                    id="select-cert-status-date"
                    value={ackCertStatus}
                    onChange={(event, newValue) => {
                      setAckCertStatus(newValue);
                    }}
                    options={certStatus === "G" ? ackCertStatusArr.filter(status => status.visible).map(option => option.code) : certStatus === "C" && ackCertStatusArr.filter(status => !status.visible).map(option => option.code)}
                    getOptionLabel={(option) =>
                      ackCertStatusArr.find(
                        (statusOption) => statusOption.code === option
                      )?.name || ""
                    }
                    label="Certificate Status"
                    autoCompleteStyle={{ width: "218px" }}
                  />
                  {errors.ackCertStatus && (
                    <Typography sx={{ color: "red", fontWeight: "300", fontSize: "14px" }}>
                      {errors.ackCertStatus}
                    </Typography>
                  )}
                </Box>
                </Box>
          </Box>
          <Box sx={{display:"flex", justifyContent: "center"}}>
            <Button type="submit"  variant="contained" sx={{ marginTop: "5px", height: "32px", borderRadius: "4px", padding: "12px", display: "flex", gap: "8px", background: "#288d2c", textTransform: "none" }} onClick={handleUpdateCertStatus}>Update Share Certificate Status</Button>
          </Box>
        </form>
          </Box>
      </Dialog>
    </Box>

  )
}