import React, { forwardRef } from "react";
import TextField from "@mui/material/TextField";

const MuiInput = forwardRef(({
  type = 'text',
  name,
  label,
  variant = "outlined",
  disabled = false,
  required = false,
  value,
  onChange,
  InputProps,
  onKeyUp,
  onKeyDown,
  onBlur,
  className,
  minRows,
  sx = {},
  style={}
}, ref) => {
  const handleInputChange = (event) => {
    if (InputProps && InputProps.maxLength && event.target.value.length > InputProps.maxLength) {
      return;
    }
    onChange(event); 
  };


  const defaultStyles = {
    width: "100%",
    backgroundColor: "#FFF",
    fontSize: "14px",
    color: "#101010",
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "black", // For WebKit browsers
    },
    ...sx,
  };

  // console.log("disabled-----", disabled);
  // console.log("required-----", type);


  return (
    <TextField
      inputRef={ref}
      minRows={minRows}
      type={type}
      onKeyUp={onKeyUp}
      onKeyDown={onKeyDown}
      label={label}
      name={name}
      className={className}
      variant={variant}
      disabled={disabled}
      required={required}
      InputProps={InputProps}
      value={value}
      onChange={handleInputChange}
      onBlur={onBlur}
      sx={defaultStyles}
      style={style}
      InputLabelProps={{ shrink: !!value || undefined }}
    />
  );
})

export default MuiInput;
